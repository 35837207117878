import { Row, Spacer } from '../Layout'
import { UserDisplayName } from './UserDisplayName'
import { UserPhoto } from './UserPhoto'

interface UserProfileProps {
  readonly userId: string
  readonly customUserPhotoClassName?: string
  readonly customUserDisplayNameClassName?: string
  readonly isEnableDisplayNameToolTip?: boolean
  readonly fallback?: React.ReactNode
  readonly fill?: boolean
}

export const UserProfile: React.FC<UserProfileProps> = ({
  userId,
  customUserPhotoClassName,
  customUserDisplayNameClassName,
  isEnableDisplayNameToolTip,
  fallback,
  fill = true,
}) => {
  return (
    <Row fill={fill} vAlign="center" style={{ display: 'flex' }}>
      <UserPhoto userId={userId} className={customUserPhotoClassName} />
      <Spacer width={'4px'} />
      <UserDisplayName
        userId={userId}
        className={customUserDisplayNameClassName}
        isEnableToolTip={isEnableDisplayNameToolTip}
        fallback={fallback}
      />
    </Row>
  )
}
