import { uuid } from '@copilot-dash/core'
import {
  INewTicketData,
  IProductData,
  IUpdateTicketStatusInfoData,
  OverViewTabs,
  ProductIds,
  TicketEmotionType,
} from '@copilot-dash/domain'
import { TicketRoute } from '../../../router'
import { ISearchScreenProps, ISearchScreenPropsQuery } from '../SearchScreen'
import { ISearchScreenStore } from './ISearchScreenStore'
import { ISearchScreenActions } from './ISearchScreenStoreActions'
import { ISearchScreenForm, ISearchScreenState } from './ISearchScreenStoreState'
import { SearchLoadMoreTicketsAction } from './actions/SearchLoadMoreTicketsAction'
import { SearchScreenTelemetryAction } from './actions/SearchScreenTelemetryAction'
import { createSearchScreenStateForm } from './createSearchScreenStateForm'

export function createSearchScreenActions(store: ISearchScreenStore, props: ISearchScreenProps): ISearchScreenActions {
  return new SearchScreenActions(store, props)
}

class SearchScreenActions implements ISearchScreenActions {
  private readonly store: ISearchScreenStore
  private props: ISearchScreenProps

  private readonly searchLoadMoreTicketsAction: SearchLoadMoreTicketsAction
  private readonly telemetryAction: SearchScreenTelemetryAction

  constructor(store: ISearchScreenStore, props: ISearchScreenProps) {
    this.store = store
    this.props = props

    this.searchLoadMoreTicketsAction = new SearchLoadMoreTicketsAction(store)
    this.telemetryAction = new SearchScreenTelemetryAction(store)
  }

  onInit(isAISearchEnable?: boolean) {
    const searchSessionId = uuid()
    this.store.setState({
      searchSessionId: searchSessionId,
    })
    this.loadMore(0, isAISearchEnable)
  }

  onStateChanged(state: ISearchScreenState, old: ISearchScreenState, isAISearchEnable?: boolean): void {
    this.telemetryAction.onStateChanged(state, old)
    if (state.form !== old.form && state.focusedTab === 'allFeedback') {
      this.store.setState({
        searchFeedbackResult: {
          tickets: [],
          total: 0,
          hasMore: true,
        },
      })
      this.searchLoadMoreTicketsAction.clearLoadMorePromise()
      this.loadMore(0, isAISearchEnable)
      this.rowClick()
    }
  }

  updateProps(newProps: ISearchScreenProps): void {
    const oldProps = this.props
    this.props = newProps
    if (newProps.query !== oldProps.query) {
      this.store.setState({
        form: createSearchScreenStateForm(newProps.query),
        focusedTab: newProps.query.tab || 'allFeedback',
        currentQuery: newProps.currentQuery,
      })
    }
  }

  selectProduct(product: IProductData): void {
    this.submit({
      product: product.name,
      defaultRange: this.store.state.form.defaultRange,
    })
  }

  submit(form: ISearchScreenForm): void {
    const searchSessionId = uuid()
    this.store.setState({
      searchSessionId: searchSessionId,
    })
    const query: ISearchScreenPropsQuery = {
      ...form,
      product: form.product || 'M365Chat',
      tab: 'allFeedback',
    }
    Logger.telemetry.trackEvent('Search/SubmitSearch', { filters: query, searchSessionId: searchSessionId })
    this.props.onNavigate(query)
  }

  loadMore(offset: number, isAISearchEnable?: boolean): void {
    this.searchLoadMoreTicketsAction.loadMoreTickets(offset, isAISearchEnable)
  }

  handleTicketsFilterPanelButtonClick = (): void => {
    this.store.setState({
      isColumnSettingPanelOpen: false,
      isTicketSummaryPanelOpen: false,
    })
    if (this.store.state.isTicketsFilterPanelOpen) {
      this.store.setState({
        isTicketsFilterPanelOpen: false,
      })
    } else {
      this.store.setState({
        isTicketsFilterPanelOpen: true,
      })
    }
  }

  handleColumnSettingButtonClick = (): void => {
    this.store.setState({
      isTicketsFilterPanelOpen: false,
      isTicketSummaryPanelOpen: false,
    })
    if (this.store.state.isColumnSettingPanelOpen) {
      this.store.setState({
        isColumnSettingPanelOpen: false,
      })
    } else {
      this.store.setState({
        isColumnSettingPanelOpen: true,
      })
    }
  }

  rowClick = (item?: INewTicketData): void => {
    if (!item) {
      this.store.setState({
        clickedTicketInfo: undefined,
        isTicketSummaryPanelOpen: false,
      })
      return
    }

    Logger.telemetry.trackEvent('Search/ClickTicket', {
      filters: this.store.state.form,
      searchSessionId: this.store.state.searchSessionId ? this.store.state.searchSessionId : '',
      ticketId: item.ticketId,
    })
    if (item.ticketId && item.productId !== ProductIds.M365Chat) {
      TicketRoute.navigator.navigateToTicketModal({ id: item.ticketId })
      return
    }

    this.store.setState({
      clickedTicketInfo: item,
      isTicketSummaryPanelOpen: true,
      isColumnSettingPanelOpen: false,
      isTicketsFilterPanelOpen: false,
    })
  }

  onDismissTicketSummaryPanel = (): void => {
    this.store.setState({
      isTicketSummaryPanelOpen: false,
    })
  }

  updateColumnSettingPanelVisibility = (visible: boolean) => {
    if (visible === this.store.state.isColumnSettingPanelOpen) {
      return
    }
    this.store.setState({
      isColumnSettingPanelOpen: visible,
    })
    Logger.telemetry.trackEvent('SwitchColumnPanel', { status: visible ? 'Open' : 'Close' })
  }

  updateFiltersPanelVisibility = (visible: boolean): void => {
    if (visible === this.store.state.isTicketsFilterPanelOpen) {
      return
    }
    this.store.setState({
      isTicketsFilterPanelOpen: visible,
    })
    Logger.telemetry.trackEvent('SwitchFiltersPanel', { status: visible ? 'Open' : 'Close' })
  }

  resetFilters(): void {
    this.submit({
      product: this.store.state.form.product,
      searchText: this.store.state.form.searchText,
      searchTextPrefix: this.store.state.form.searchTextPrefix,
      email: this.store.state.form.email,
      userId: this.store.state.form.userId,
      tenantIds: this.store.state.form.tenantIds,
      defaultRange: this.store.state.form.defaultRange,
    })
  }

  updateTicketStatus(ticketId: string, info: IUpdateTicketStatusInfoData) {
    this.store.setState((state) => {
      const ticket = state.searchFeedbackResult.tickets.find((ticket) => ticket.ticketId === ticketId)
      if (ticket) {
        ticket.teamArea = info.areaPath
        ticket.status = info.status
        ticket.priority = info.priority
        ticket.rootCauseList = info.issueList.map((item) => ({
          issueId: item.issueId,
          rootCauseTitle: item.title,
        }))
        ticket.reasoning = info.reasoning
        ticket.emotionType = ticket.emotionType ?? TicketEmotionType.Negative
        ticket.closedComment = info.closedComment
        ticket.assignTo = info.assignTo
        ticket.customTags = info.customTags
        app.store.actions.updateAllCustomTags(ticket.customTags ?? [])
      }
    })
  }

  updateFocusedTab(tab: OverViewTabs): void {
    const form = this.store.state.form
    const query: ISearchScreenPropsQuery = {
      product: form.product || 'M365Chat',
      tab: tab,
    }
    this.props.onNavigate(query)
  }
}
