import { useState, FC, useMemo, useEffect } from 'react'
import { Combobox, mergeClasses, Option, Persona, Spinner } from '@fluentui/react-components'
import type { ComboboxProps } from '@fluentui/react-components'
import { useStyles } from './CopilotDashSearchableDropdown.styles'
import { IFilterOption } from './IFilterOption.types'
import { AsyncSnapshot } from '@copilot-dash/core'
import { Column, Row } from '../Layout'
import { PeopleListRegular } from '@fluentui/react-icons'

interface IProps {
  comboId: string
  defaultSelectedOption: string[] | undefined
  onChangeFilter: (item: string[] | undefined) => void
  snapshot: AsyncSnapshot<IFilterOption[]>
  customSearchOptionHint?: string
  keyword?: string
  setkeyword?: React.Dispatch<React.SetStateAction<string | undefined>>
  className?: string
  supportCustomSearchOption?: boolean
}

export const CopilotDashSearchableDropdown: FC<IProps> = ({
  comboId,
  defaultSelectedOption,
  onChangeFilter,
  snapshot,
  keyword = '',
  setkeyword,
  className,
  customSearchOptionHint = '',
  supportCustomSearchOption = false,
}) => {
  const styles = useStyles()
  const filterOptions: IFilterOption[] = useMemo(() => {
    switch (snapshot.status) {
      case 'done':
        return snapshot.data
      default:
        return []
    }
  }, [snapshot.data, snapshot.status])

  const [selectedOptionValue, setSelectedOptionValue] = useState<string[] | undefined>(defaultSelectedOption)
  const [isChangedOption, setIsChangedOption] = useState<boolean>(false)

  let defaultOptionsValue = keyword
  if (!isChangedOption) {
    const defaultOptionsKeyValue = defaultSelectedOption
      ? defaultSelectedOption.map((key) => {
          const option = filterOptions.find((option) => option.key === key)
          return option ? option : { key: key, text: key }
        })
      : []
    defaultOptionsValue = defaultOptionsKeyValue.map((option) => option?.text).join(', ')
  }

  const [value, setValue] = useState<string>(defaultOptionsValue)

  const onChange: ComboboxProps['onChange'] = (event) => {
    const value = event.target.value
    if (value === '') {
      onChangeFilter([])
    }
    setValue(value)
    setIsChangedOption(true)
    setkeyword && setkeyword(value)
  }

  useEffect(() => {
    setValue(defaultOptionsValue)
  }, [defaultOptionsValue])

  const onOptionSelect: ComboboxProps['onOptionSelect'] = (event, data) => {
    setSelectedOptionValue(data.selectedOptions)
    setIsChangedOption(true)
    if (event.type === 'click') {
      onChangeFilter(data.selectedOptions)
      const defaultOptionsKeyValue = data.selectedOptions
        ? data.selectedOptions.map((key) => {
            const option = filterOptions.find((option) => option.key === key)
            return option ? option : { key: key, text: key }
          })
        : []

      const defaultOptionsValue = defaultOptionsKeyValue.map((option) => option?.text).join(', ')
      setValue(defaultOptionsValue)
    }
  }

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      setIsChangedOption(true)
      setSelectedOptionValue([keyword])
      onChangeFilter([keyword])
    }
  }

  return (
    <Row className={styles.container}>
      <PeopleListRegular className={styles.peopleIcon} />
      <Combobox
        aria-labelledby={comboId}
        freeform
        placeholder="From Tenant"
        value={value}
        onChange={onChange}
        onOptionSelect={onOptionSelect}
        listbox={{ className: styles.ComboboxFilter }}
        className={mergeClasses(className, styles.ComboboxFilterContainer)}
        defaultSelectedOptions={selectedOptionValue}
        onKeyDown={onKeyDown}
        clearable
      >
        <RenderContent
          snapshot={snapshot}
          value={value}
          customSearchOptionHint={customSearchOptionHint}
          supportCustomSearchOption={supportCustomSearchOption}
        />
      </Combobox>
    </Row>
  )
}

function RenderContent({
  snapshot,
  value,
  customSearchOptionHint,
  supportCustomSearchOption,
}: {
  snapshot: AsyncSnapshot<IFilterOption[]>
  value: string
  customSearchOptionHint?: string | undefined
  supportCustomSearchOption?: boolean
}) {
  const styles = useStyles()
  switch (snapshot.status) {
    case 'none':
      return (
        <Option key={value} text={value} disabled={!supportCustomSearchOption}>
          Search for {customSearchOptionHint}: &quot;{value}&quot;
        </Option>
      )
    case 'waiting':
      return <Spinner className={styles.loading} />
    case 'done':
      return (
        <Column>
          {value && (
            <Option key={value} text={value} disabled={!supportCustomSearchOption}>
              Search for {customSearchOptionHint}: &quot;{value}&quot;
            </Option>
          )}
          {snapshot.data.map((option) => (
            <Option key={option.key} text={option.text} value={option.key}>
              <Persona
                avatar={{ color: 'colorful', 'aria-hidden': true }}
                name={option.text}
                secondaryText={option.key}
              />
            </Option>
          ))}
        </Column>
      )
    case 'error':
      return (
        <Option key="freeform" disabled>
          {`${snapshot.error}`}
        </Option>
      )
  }
}
