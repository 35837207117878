import { Body1, Body1Strong } from '@fluentui/react-components'
import { FC, Fragment } from 'react'
import { Column, Row } from '../../../../components/Layout'
import { ISearchScreenForm, useSearchScreenActions, useSearchScreenState } from '../../store'
import { useStyles } from './SearchScreenTag.styles'
import { TagCounter } from './TagCounter'
import { TimeRangeTag } from './TimeRangeTag'
import { ITagDefinition } from './ITagDefinition.type'
import { SearchScreenCopilotDashTag } from './SearchScreenCopilotDashTag'
import {
  AgentTypesTagConfig,
  AppTypesTagConfig,
  HasVerbatimConfig,
  PriorityTagConfig,
  RingTagConfig,
  ClientTagConfig,
  EmotionTypeTagConfig,
  CustomerTagConfig,
  GroundedPromptsTagConfig,
  HasCitationTagConfig,
  HasEntityCardTagConfig,
  HasUserConsentConfig,
  HitAvalonTagConfig,
  InvocationTypeTagConfig,
  IsApologyTagConfig,
  LanguageTagConfig,
  TriggeredSkillTagConfig,
  IsSTCAChinaTagConfig,
  IsTopi18NTagConfig,
  ResponseHeroTypeTagConfig,
  ResponseLinkTypeTagConfig,
  SemanticSearchTypeTagConfig,
  BizchatScenarioTagConfig,
  ExperienceTypeConfig,
  HasConnectorTagConfig,
  HasMessageExtensionTagConfig,
  HasGPTExtensionTagConfig,
  HasCopilotExtensionIdsTagConfig,
  OptionsSetsTagConfig,
  ErrorCodeConfig,
  ChannelTagConfig,
  IsGCIntentTagConfig,
  HasConnectorResultTagConfig,
  DSATStatusTagConfig,
  CustomTagsConfig,
  CopilotExtensionIdsTagConfig,
  SliceIdsTagConfig,
  FlightsTagConfig,
  HasErrorMessagesTagConfig,
} from './index'

interface IProps {
  onClickTagFunction: () => void
  isSmallScreen: boolean
}

export const SearchScreenTag: FC<IProps> = ({ onClickTagFunction, isSmallScreen }) => {
  const styles = useStyles()
  const actions = useSearchScreenActions()
  const form = useSearchScreenState((state) => state.form)

  const SUPPORTED_TAGS: ITagDefinition[] = [
    AgentTypesTagConfig(),
    AppTypesTagConfig(),
    ClientTagConfig(),
    CustomerTagConfig(),
    RingTagConfig(),
    ChannelTagConfig(),
    EmotionTypeTagConfig(),
    HasVerbatimConfig(),
    HasUserConsentConfig(),
    PriorityTagConfig(),
    LanguageTagConfig(),
    BizchatScenarioTagConfig(),
    GroundedPromptsTagConfig(),
    InvocationTypeTagConfig(),
    IsApologyTagConfig(),
    HasCitationTagConfig(),
    TriggeredSkillTagConfig(),
    HitAvalonTagConfig(),
    IsSTCAChinaTagConfig(),
    HasEntityCardTagConfig(),
    IsTopi18NTagConfig(),
    ResponseHeroTypeTagConfig(),
    ResponseLinkTypeTagConfig(),
    SemanticSearchTypeTagConfig(),
    ExperienceTypeConfig(),
    HasConnectorTagConfig(),
    HasMessageExtensionTagConfig(),
    HasGPTExtensionTagConfig(),
    HasCopilotExtensionIdsTagConfig(),
    OptionsSetsTagConfig(),
    ErrorCodeConfig(),
    IsGCIntentTagConfig(),
    HasConnectorResultTagConfig(),
    DSATStatusTagConfig(),
    CustomTagsConfig(),
    CopilotExtensionIdsTagConfig(),
    SliceIdsTagConfig(),
    FlightsTagConfig(),
    HasErrorMessagesTagConfig(),
  ]

  function onRemoveTag(key: keyof ISearchScreenForm, e?: React.MouseEvent) {
    e?.stopPropagation()
    actions.submit({ ...form, [key]: [] })
  }

  function renderFoldedTag(tag: ITagDefinition) {
    return (
      <Column>
        <Body1Strong>{tag.tagName}:&nbsp;</Body1Strong>
        <Body1>{tag.toolTipContentValue}</Body1>
      </Column>
    )
  }

  function renderFoldedTags(tags: ITagDefinition[]) {
    return (
      <Column>
        {tags.map((tag, index) => (
          <Fragment key={index}>{renderFoldedTag(tag)}</Fragment>
        ))}
      </Column>
    )
  }
  // Filter out tags that are not supported, have no value, or are empty arrays
  const validTags = SUPPORTED_TAGS.filter((tag) => {
    const value = form[tag.key]
    return value && (!Array.isArray(value) || value.length > 0) && !tag.IsShowTag
  })

  const firstTag = validTags.length === 0 ? null : validTags[0]
  const firstTagView = firstTag && (
    <SearchScreenCopilotDashTag
      tagKey={firstTag.key}
      toolTipContentValue={firstTag.toolTipContentValue}
      truncatedTagValue={firstTag.truncatedTagValue}
      tagName={firstTag.tagName}
      onRemoveTag={(e?: React.MouseEvent) => onRemoveTag(firstTag.key, e)}
      onClickTagFunction={onClickTagFunction}
      isSmallScreen={isSmallScreen}
      isTagClearable={firstTag.isTagClearable}
    />
  )

  const foldedTags = validTags.slice(1)
  const foldedTagsView = foldedTags.length > 0 ? renderFoldedTags(foldedTags) : <></>

  return (
    <Row className={styles.container}>
      {firstTagView}
      <TimeRangeTag onClickTagFunction={onClickTagFunction} isSmallScreen={isSmallScreen} />
      {foldedTags.length > 0 && (
        <TagCounter tagCount={foldedTags.length} foldedTags={foldedTagsView} onClickTagFunction={onClickTagFunction} />
      )}
    </Row>
  )
}
