import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  container: {
    ...shorthands.flex(1),
  },
  header: {
    alignItems: 'center',
    marginTop: '20px',
  },
  search: {
    marginBottom: '16px',
  },
  expandIcon: {
    marginRight: '20px',
    minWidth: '22px',
  },
  flexGrow: {
    flexGrow: 1,
  },
  breakWord: {
    wordBreak: 'break-word',
  },
  minGap: {
    minWidth: '40px',
  },
  maxGap: {
    maxWidth: '86px',
    flexShrink: 1,
    flexGrow: 1,
  },

  rootCauseTitle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  subInfo: {
    alignItems: 'center',
    width: 'auto',
    flexWrap: 'wrap',
    marginBottom: '20px',
  },

  editBtn: {
    minWidth: '152px',
    color: tokens.colorBrandForeground1,
  },
  customUserPhoto: {
    width: '20px',
    height: '20px',
    ...shorthands.borderRadius('50%'),
  },
  customUserName: {
    fontSize: tokens.fontSizeBase300,
    fontWeight: tokens.fontWeightSemibold,
  },
  clock: {
    fontSize: '20px',
    verticalAlign: 'middle',
  },
  addETA: {
    fontWeight: 600,
    fontSize: '14px',
  },
  dueDays: {
    height: '20px',
    padding: '0px 4px',
    marginLeft: '8px',
    color: '#515EF5',
    fontSize: '12px',
    fontWeight: 600,
    textAlign: 'center',
    background: 'rgba(81, 94, 245,.1)',
    borderRadius: '4px',
  },
  overDue: {
    height: '20px',
    width: '55px',
    marginLeft: '8px',
    color: '#F55151',
    fontSize: '12px',
    fontWeight: 600,
    textAlign: 'center',
    background: 'rgba(245, 81, 81,.1)',
    borderRadius: '4px',
  },

  empty: {
    color: tokens.colorNeutralForeground4,
  },
})
