import { TelemetryScope } from '@copilot-dash/logger'
import { DashSettingsProvider } from '@copilot-dash/settings'
import { DashStoreProvider } from '@copilot-dash/store'
import { ErrorViewBoundary } from './components/Error'
import { GlobalProductProvider } from './providers/product'
import { GlobalToastProvider } from './providers/toast/GlobalToastProvider'
import { AppRouterProvider } from './router'
import { ThemeProvider } from './theme/ThemeProvider'

export function DashboardApp() {
  return (
    <ErrorViewBoundary level="global" label="DashboardApp">
      <TelemetryScope scope="App">
        <DashSettingsProvider settings={app.settings}>
          <DashStoreProvider store={app.store}>
            <ThemeProvider>
              <GlobalToastProvider>
                <GlobalProductProvider>
                  <AppRouterProvider router={app.router} />
                </GlobalProductProvider>
              </GlobalToastProvider>
            </ThemeProvider>
          </DashStoreProvider>
        </DashSettingsProvider>
      </TelemetryScope>
    </ErrorViewBoundary>
  )
}
