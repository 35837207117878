import { FC, memo, useEffect } from 'react'
import { Column, Spacer } from '../../../../components/Layout'
import { TopIssue } from './children/topIssue'
import { useStyles } from './FeedbackInsights.styles'
import { VIPUserDSATs } from './children/vipUserDSATs'
import { ErrorViewBoundary } from '../../../../components/Error'
import { TelemetryScope } from '@copilot-dash/logger'
import { useSearchScreenActions } from '../../store'
import { SearchRoute } from '../../../../router'
import { getProductIdByName } from '@copilot-dash/domain'
import { Empty } from '../../../../components/Empty/Empty'

export const FeedbackInsights: FC = memo(() => {
  const styles = useStyles()
  const actions = useSearchScreenActions()
  useEffect(() => {
    actions.onDismissTicketSummaryPanel()
  }, [actions])

  const productName = SearchRoute.navigator.useArgsOptional()?.product
  const productId = getProductIdByName(productName)
  const batchList = application.store.use((state) =>
    typeof productId === 'number' ? state.topIssue.batchList?.[productId] : null,
  )
  if (batchList && batchList.status === 'done' && batchList.data.length === 0) {
    return <Empty title="No data" subTitle="There's no feedback insights data generated yet, but it's coming soon! " />
  }

  return (
    <ErrorViewBoundary label="FeedbackInsights">
      <TelemetryScope scope="FeedbackInsights">
        <Column className={styles.container}>
          <TopIssue />
          <Spacer height={34} />
          <VIPUserDSATs />
        </Column>
      </TelemetryScope>
    </ErrorViewBoundary>
  )
})

FeedbackInsights.displayName = 'FeedbackInsights'
