import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Column, Row, Spacer } from '../../../components/Layout'
import {
  ArrowUpFilled,
  ChevronDownRegular,
  ChevronRightFilled,
  ErrorCircleFilled,
  LinkMultipleFilled,
  NewFilled,
} from '@fluentui/react-icons'
import { Text, Button, Spinner, Tag, tokens } from '@fluentui/react-components'
import { INewTicketData, ITopIssue } from '@copilot-dash/domain'
import { useTeamViewStore } from '../store'
import { ExtendedTableColumnDefinition } from '../../../components/Table/ExtendedTableColumn'
import { TableLoading } from './TableLoading'
import { ErrorView } from '../../../components/Error'
import { ResizableColumnsTable, ITableRef } from '../../../components/Table/ResizableColumnsTable'
import { useStyles } from './TopIssueTickets.styles'
import { motion } from 'framer-motion'
import { ALL_TEAMS } from '../../../store/data/team/createTeamStore'
import { RouteLink, SearchRoute } from '../../../router'

interface IClusteringTickets {
  batchId: string | null | undefined
  issue: ITopIssue
  columns: ExtendedTableColumnDefinition<INewTicketData>[]
  clickedTicketKey?: string
  keyPicker: (item: INewTicketData, index: number) => string
  onRowClick?: (row?: INewTicketData) => void
  onSelectionChange?: (selectedItems: INewTicketData[] | undefined) => void
  tableRef?: (ref: ITableRef | null) => void
  estimatedHeight?: number
  hideNewTag?: boolean
  topIssueLength: number
}

function TopIssueTicketsComp({
  batchId,
  issue,
  columns,
  clickedTicketKey,
  keyPicker,
  onRowClick,
  onSelectionChange,
  tableRef,
  estimatedHeight = 600,
  hideNewTag = false,
  topIssueLength,
}: IClusteringTickets) {
  const rowRef = useRef<HTMLDivElement>(null)
  const styles = useStyles()

  const filter = useTeamViewStore((state) => state.tickets.filterForm)

  const teamId = useTeamViewStore.getState().teams.computed.selectedTeamId
  const teamName = useTeamViewStore.getState().teams.lastSelectedTeam
  const isAllTeams = teamName === ALL_TEAMS
  const routeHash = useTeamViewStore.getState().route.args.hash

  const ticketsSnapshot = useTeamViewStore((state) => state.tickets.topIssueTickets[`${batchId}-${issue.issueId}`])

  const [loading, setLoading] = useState(false)
  const [expanded, setExpanded] = useState(routeHash === issue.issueId)

  useEffect(() => {
    if (issue.issueId === routeHash && rowRef.current) {
      rowRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [issue.issueId, routeHash])

  useEffect(() => {
    if (batchId && typeof teamId === 'number' && issue.issueId) {
      useTeamViewStore.getState().tickets.fetchTopIssueTickets(issue.issueId, teamId, batchId, filter)
    }
  }, [batchId, issue.issueId, filter, teamId])

  const handleLoadMore = useCallback(() => {
    const offset = ticketsSnapshot?.data?.tickets?.length ?? 0
    if (
      typeof teamId === 'number' &&
      batchId &&
      issue.issueId &&
      ticketsSnapshot?.status === 'done' &&
      ticketsSnapshot.data.hasMore
    ) {
      setLoading(true)
      useTeamViewStore
        .getState()
        .tickets.loadMoreTopIssueTickets(issue.issueId, teamId, batchId, offset)
        .finally(() => {
          setLoading(false)
        })
    }
  }, [teamId, batchId, issue.issueId, ticketsSnapshot])

  const table = useMemo(() => {
    if (ticketsSnapshot?.status === 'waiting') {
      return <TableLoading className={styles.loading} style={{ height: estimatedHeight }} />
    }
    if (ticketsSnapshot?.status === 'error') {
      return <ErrorView error={ticketsSnapshot.error} />
    }
    if (ticketsSnapshot?.status === 'done') {
      return ticketsSnapshot.data?.ticketCount > 0 ? (
        <ResizableColumnsTable
          ref={(ref) => tableRef?.(ref)}
          items={ticketsSnapshot.data?.tickets}
          columns={columns}
          keyPicker={keyPicker}
          rowClick={onRowClick}
          selectedRowId={clickedTicketKey}
          enableBodyScroll={false}
          selectable={!isAllTeams}
          selectionMode="multiselect"
          onSelectionChange={onSelectionChange}
        />
      ) : (
        <ErrorView.Custom level="WARNING" message="No tickets found for this query" />
      )
    }
    return null
  }, [
    ticketsSnapshot,
    styles.loading,
    estimatedHeight,
    columns,
    keyPicker,
    onRowClick,
    clickedTicketKey,
    onSelectionChange,
    tableRef,
    isAllTeams,
  ])

  const hasMore =
    ticketsSnapshot?.data?.hasMore &&
    ticketsSnapshot.data.ticketCount - (ticketsSnapshot.data?.tickets?.length ?? 0) > 0

  useEffect(() => {
    // clear tickets in store when unmount
    return () => {
      useTeamViewStore.getState().tickets.clearTopIssueTickets(`${batchId}-${issue.issueId}`)
    }
  }, [issue.issueId, batchId])

  const issueTag = useMemo(() => {
    if (!hideNewTag && issue.isNew) {
      return (
        <Text className={styles.tag}>
          <NewFilled className={styles.tagIcon} />
          New Issue(Converge {issue.coverage}%)
        </Text>
      )
    }
    if (issue.trends) {
      return (
        <Text className={styles.tag}>
          <ArrowUpFilled className={styles.tagIcon} />
          Top Trending(WoW +{issue.trends}%, Converge {issue.coverage}%)
        </Text>
      )
    }
    if (issue.coverage) {
      return (
        <Text className={styles.tag}>
          <ErrorCircleFilled className={styles.tagIcon} />
          Top Coverage({issue.coverage}%)
        </Text>
      )
    }
    return null
  }, [hideNewTag, issue, styles.tag, styles.tagIcon])

  const routePath = useMemo(() => {
    const currentArgs = useTeamViewStore.getState().route.args
    if (!currentArgs || !batchId || !issue.issueId) return ''
    return SearchRoute.navigator.generatePath({
      product: currentArgs.product,
      tab: 'feedbackInsights',
      issueBatchId: batchId,
      issueId: issue.issueId,
    })
  }, [issue.issueId, batchId])

  if (ticketsSnapshot?.status === 'done' && ticketsSnapshot.data?.ticketCount === 0) {
    if (topIssueLength === 1) {
      return <ErrorView.Custom level="WARNING" message="No tickets found for this query" />
    } else {
      return null
    }
  }

  return (
    <Column className={styles.wrapper}>
      <Row ref={rowRef} id={issue.issueId} className={styles.accordionHeader} onClick={() => setExpanded((v) => !v)}>
        <Row>
          <motion.div className={styles.icon} animate={{ rotate: expanded ? 90 : 0 }}>
            <ChevronRightFilled fontSize={22} />
          </motion.div>
          <Spacer width={8} />
          <Text size={400} weight="semibold">
            <Tag style={{ marginRight: '12px', width: '40px', height: '22px' }}>
              <Text
                style={{
                  fontWeight: 600,
                  fontSize: '14px',
                  color:
                    issue.priority === 0
                      ? tokens.colorStatusDangerForeground1
                      : issue.priority === 1
                        ? tokens.colorPaletteCornflowerForeground2
                        : tokens.colorNeutralStrokeAccessibleHover,
                }}
              >
                P{issue.priority}
              </Text>
            </Tag>
            Issue: {issue.issueTitle}
            {isAllTeams ? null : (
              <RouteLink path={routePath} style={{ marginLeft: '4px', marginRight: '16px' }}>
                <LinkMultipleFilled fontSize={18} className={styles.link} />
              </RouteLink>
            )}
            <Text
              size={300}
              style={{ marginRight: '16px', fontWeight: 600, color: tokens.colorNeutralStrokeAccessibleHover }}
            >
              Team: {teamName}
            </Text>
            <Text size={300} style={{ fontWeight: 600, color: tokens.colorNeutralStrokeAccessibleHover }}>
              DSAT: {issue.ticketCount}, Tenant: {issue.impactedTenantsCount}
            </Text>
          </Text>
        </Row>
        <Spacer />
        {issueTag}
      </Row>
      <motion.div
        layout
        animate={expanded ? 'expanded' : 'collapsed'}
        className={styles.accordionContent}
        variants={{
          collapsed: { height: 0, paddingTop: 0 },
          expanded: { height: 'auto', paddingTop: 16 },
        }}
        initial={false}
      >
        {table}
        {hasMore && (
          <Row hAlign="center" className={styles.loadMore}>
            {loading ? (
              <Spinner />
            ) : (
              <Button
                className={styles.loadMoreTickets}
                onClick={handleLoadMore}
                icon={<ChevronDownRegular />}
                iconPosition="after"
              >
                Load more tickets ({ticketsSnapshot.data.ticketCount - (ticketsSnapshot.data?.tickets?.length ?? 0)})
              </Button>
            )}
          </Row>
        )}
      </motion.div>
    </Column>
  )
}

export const TopIssueTickets = memo(TopIssueTicketsComp)

TopIssueTickets.displayName = 'TopIssueTickets'
