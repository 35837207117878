import { Button, Dialog, DialogBody, DialogSurface, DialogTitle, makeStyles } from '@fluentui/react-components'
import { DismissRegular } from '@fluentui/react-icons'
import { useState } from 'react'

interface ImageModalProps {
  imageUrl: string
  onClose: () => void
}

export const ImagePreviewModal: React.FC<ImageModalProps> = ({ imageUrl, onClose }) => {
  const [scale, setScale] = useState(1)
  const styles = useStyles()
  const handleWheel = (event: React.WheelEvent<HTMLImageElement>) => {
    const newScale = scale - event.deltaY * 0.005
    setScale(Math.min(Math.max(newScale, 0.1), 3))
  }

  return (
    <Dialog open={!!imageUrl} onOpenChange={onClose}>
      <DialogSurface className={styles.dialogSurface}>
        <DialogTitle
          action={<Button icon={<DismissRegular />} appearance="subtle" onClick={onClose} aria-label="Close" />}
        >
          Image Preview
        </DialogTitle>
        <DialogBody onWheel={handleWheel} className={styles.body}>
          <img
            src={imageUrl}
            style={{
              transform: `scale(${scale})`,
            }}
            className={styles.img}
          />
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}

export const useStyles = makeStyles({
  dialogSurface: {
    padding: '4px',
  },
  body: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px',
  },

  img: {
    width: '80%',
    margin: '0 auto',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
    transition: 'transform 0.3s ease-in-out',
  },
})
