import { ApiConversationGroup4 } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getBlobMetadata } from '../actions-blob/getBlobMetadata'
import { getRawConversationIndex } from './getRawConversationIndex'

export function getRawConversationGroup4(
  context: IDashStoreContext,
  ticketId: string,
  turnId: string,
): PromiseSnapshot<ApiConversationGroup4> {
  return context.getOrFetch<ApiConversationGroup4>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[turnId]?.rawConversationGroup4
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[turnId] ??= {})
      turn.rawConversationGroup4 = snapshot
    },
    fetch: async () => {
      // Get turn index
      const turnIndex = await getRawConversationIndex(context, ticketId, turnId)

      // Get from grouped file
      const files = await getBlobMetadata(context, ticketId).promise
      const file = files.groupedConversationFiles.find((item) => item.index === turnIndex)?.group4
      if (!file) {
        throw TicketError.create('NoConversations', { ticketId, ticketMessageId: turnId })
      }

      return await context.api.ods.getConversationGroup4(file.fileUrl)
    },
  })
}
