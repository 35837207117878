import { InlineDrawer, makeStyles, tokens } from '@fluentui/react-components'
import { TicketScreenDrawerType } from '../../store/IState'
import { TicketScreenDrawerBody } from './TicketScreenDrawerBody'
import { TicketScreenDrawerHeader } from './TicketScreenDrawerHeader'
import { Row } from '../../../../components/Layout'
import { TicketScreenDrawerResizer } from './TicketScreenDrawerResizer'
import { TicketScreenStore } from '../../TicketScreenStore'
import { useRef, useState } from 'react'

interface IProps {
  readonly type: TicketScreenDrawerType
}

export function TicketScreenDrawer({ type }: IProps) {
  const styles = useStyles()

  const open = TicketScreenStore.use((state) => state.activeDrawer === type)

  const drawerRef = useRef<HTMLDivElement>(null)

  const [drawerWidth, setDrawerWidth] = useState(() => {
    switch (type) {
      case TicketScreenDrawerType.TicketContext:
        return application.settings.user.ticketContextDrawerWidth.getValue()
      case TicketScreenDrawerType.DiscussionPanel:
        return application.settings.user.discussionPanelDrawerWidth.getValue()
      case TicketScreenDrawerType.ActivityHistory:
        return application.settings.user.activityHistoryDrawerWidth.getValue()
    }
  })
  return (
    <Row>
      <TicketScreenDrawerResizer type={type} drawerRef={drawerRef} setDrawerWidth={setDrawerWidth} open={open} />
      <InlineDrawer
        open={open}
        position="end"
        size="large"
        as="aside"
        ref={drawerRef}
        style={{ width: `${drawerWidth}px` }}
        className={styles.root}
      >
        <TicketScreenDrawerHeader type={type} />
        <TicketScreenDrawerBody type={type} />
      </InlineDrawer>
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    height: '100%',
    top: 0,
    bottom: 0,
    backgroundColor: tokens.colorNeutralBackground1,
    boxShadow: tokens.shadow64,
    borderTopLeftRadius: tokens.borderRadiusMedium,
    borderBottomLeftRadius: tokens.borderRadiusMedium,
  },
})
