import { Column } from '../../../../../components/Layout'
import { SettingsScreenSection } from '../../common/SettingsScreenSection'
import { SettingsScreenSectionTitle } from '../../common/SettingsScreenSectionTitle'
import { FeatureFlightTile } from '../tiles/FeatureFlightTile'
export function SettingsLabsPanel() {
  return (
    <Column hAlign="start">
      {/* 1. Experimental Features */}
      <SettingsScreenSection>
        <SettingsScreenSectionTitle title="Experimental Features" />
        <FeatureFlightTile flight="EnableTicketBasicInfo" />
        <FeatureFlightTile flight="EnableMcpExperimentFeedbackBoard" />
        <FeatureFlightTile flight="EnableAISearch" />
        <FeatureFlightTile flight="EnableErrorMessagesFilter" />
      </SettingsScreenSection>
    </Column>
  )
}
