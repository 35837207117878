import { PromiseSnapshot } from '@copilot-dash/core'
import { IBlobMetadata } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getTicketSession } from '../actions-ticket/getTicketSession'
import { validateTicketUserConsent } from '../validators/validateTicketUserConsent'
import { convertToBlobMetadata } from './convertToBlobMetadata'

export function getBlobMetadata(context: IDashStoreContext, ticketId: string): PromiseSnapshot<IBlobMetadata> {
  return context.getOrFetch<IBlobMetadata>({
    get: (state) => {
      return state.tickets[ticketId]?.blobManifest
    },
    set: (state, snapshot) => {
      const item = (state.tickets[ticketId] ??= {})
      item.blobManifest = snapshot
    },
    fetch: async () => {
      // 1. Check user consent
      const session = await getTicketSession(context, ticketId).promise
      validateTicketUserConsent(ticketId, session)

      const files = await context.api.ods.getFiles(ticketId)
      return convertToBlobMetadata(files)
    },
  })
}
