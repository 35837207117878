import { ProductIds } from '@copilot-dash/domain'
import { useUserSetting } from '@copilot-dash/settings'
import { forwardRef, memo, useEffect, useImperativeHandle, useRef } from 'react'
import { useForm } from 'react-hook-form'
import {
  AgentCard,
  CustomFiltersCard,
  DateCard,
  FeedbackDetailsCard,
  FeedbackSourceCard,
  PromptDetailsCard,
  QueryProcessingCard,
  ResponseDetailsCard,
  FeedbackHandlingCard,
} from '../cards'
import {
  ITicketFilterFormData,
  ITicketFilterFormProps,
  ITicketFilterFormRef,
  WatchFn,
  customFiltersToAdd,
} from './TicketFilterForm.types'
import { useTicketFilterStore } from '../../store/store'

export const TicketFilterForm = memo(
  forwardRef<ITicketFilterFormRef, ITicketFilterFormProps>(({ onSubmit, defaultValues }, ref) => {
    const { control, getValues, setValue, watch } = useForm<ITicketFilterFormData>({ defaultValues: {} })

    const [searchScreenCustomizedFilters, setSearchScreenCustomizedFilters] = useUserSetting(
      'searchScreenCustomizedFilterItems',
    )
    const [teamViewCustomizedFilters, setTeamViewCustomizedFilters] = useUserSetting('teamViewCustomizedFilterItems')
    const selectedProductId = useTicketFilterStore((state) => state.selectedProductId)
    const selectDateRangeWithTime = useTicketFilterStore((state) => state.selectDateRangeWithTime)

    const watchFnRef = useRef<WatchFn | null>(null)

    useEffect(() => {
      const subscription = watch((value, { type }) => {
        type && onSubmit(value as ITicketFilterFormData)
      })
      return () => subscription.unsubscribe()
    }, [watch, onSubmit])

    useEffect(() => {
      defaultValues &&
        Object.entries(defaultValues).forEach(([key, value]) => {
          setValue(key as keyof ITicketFilterFormData, value)
        })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Sync the customized filters in UserSetting based on the default values
    useEffect(() => {
      const newSearchScreenFilters = [...searchScreenCustomizedFilters]
      const newTeamViewFilters = [...teamViewCustomizedFilters]
      customFiltersToAdd.forEach((filter) => {
        if (defaultValues?.[filter.key]) {
          if (!searchScreenCustomizedFilters.includes(filter.name)) {
            newSearchScreenFilters.push(filter.name)
          }
          if (!teamViewCustomizedFilters.includes(filter.name)) {
            newTeamViewFilters.push(filter.name)
          }
        }
      })
      setSearchScreenCustomizedFilters(newSearchScreenFilters)
      setTeamViewCustomizedFilters(newTeamViewFilters)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useImperativeHandle(
      ref,
      () => ({
        setValues: (values) => {
          values &&
            Object.entries(values).forEach(([key, value]) => {
              setValue(key as keyof ITicketFilterFormData, value)
            })
        },
        getValues: () => getValues(),
        watch: (fn) => {
          watchFnRef.current = fn
        },
      }),
      [getValues, setValue],
    )

    return (
      <form>
        {/* Date */}
        <DateCard control={control} withTime={selectDateRangeWithTime} />

        {/* Feedback source */}
        <FeedbackSourceCard control={control} />

        {/* Feedback details */}
        <FeedbackDetailsCard control={control} />

        {/* Prompt details */}
        {selectedProductId !== ProductIds.TenantAdminFeedback && <PromptDetailsCard control={control} />}

        {/* Response details */}
        {selectedProductId !== ProductIds.TenantAdminFeedback && <ResponseDetailsCard control={control} />}

        {/* Query processing */}

        <QueryProcessingCard control={control} />
        <FeedbackHandlingCard control={control} />

        {/* Agent details */}

        <AgentCard control={control} />

        {/* Custom filters */}

        <CustomFiltersCard control={control} />
      </form>
    )
  }),
)
TicketFilterForm.displayName = 'TicketFilterForm'
