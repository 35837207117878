import { FC, memo, useState, useEffect } from 'react'
import { Field, Dropdown, Option, Text, Checkbox, makeStyles, tokens } from '@fluentui/react-components'
import { isNil } from 'lodash'
import { ITeamData } from '@copilot-dash/domain'
import { Row } from '../../Layout'
export const ROOT_CAUSE_PRIORITY_OPTIONS = [1, 2, 3, 4] as const

interface IProps {
  teamId: number | undefined
  isTeamShared?: boolean
  onTeamIdChange: (value: number) => void
  onIsTeamSharedChange: (value: boolean) => void
  orientation?: 'horizontal' | 'vertical'
  className?: string
  style?: React.CSSProperties
  disabled?: boolean
  fieldProps?: Partial<React.ComponentProps<typeof Field>>
  teamsOptions: ITeamData[]
}

export const Team: FC<IProps> = memo(
  ({
    teamId,
    isTeamShared = false,
    onTeamIdChange,
    onIsTeamSharedChange,
    orientation = 'vertical',
    className,
    style,
    disabled,
    fieldProps,
    teamsOptions,
  }) => {
    const styles = useStyles()
    const [teamName, setTeamName] = useState(teamsOptions.find((team) => team.id === teamId)?.name)

    useEffect(() => {
      if (isNil(teamId)) {
        setTeamName(teamsOptions.find((team) => team.id === teamId)?.name)
      }
    }, [teamId, teamsOptions])

    return (
      <Field
        className={className}
        orientation={orientation}
        style={style}
        {...(fieldProps ?? {})}
        onClick={(e) => e.stopPropagation()}
      >
        <Row>
          <Checkbox
            checked={isTeamShared}
            onChange={(ev, data) => onIsTeamSharedChange(!!data.checked)}
            label={
              <Text size={400} weight="semibold">
                Share with team{' '}
              </Text>
            }
            disabled={disabled}
          />
          {isTeamShared && (
            <Dropdown
              disabled={disabled}
              value={teamName}
              onOptionSelect={(_, data) => data.selectedOptions[0] && onTeamIdChange(Number(data.selectedOptions[0]))}
              appearance="underline"
              placeholder="Select a Team"
              positioning={{ align: 'end', pinned: true, position: 'below' }}
              button={{ className: styles.dropdown }}
            >
              {[...teamsOptions]
                .sort((a, b) => a.name.toLocaleUpperCase().trim().localeCompare(b.name.toLocaleUpperCase().trim()))
                .map((item) => (
                  <Option key={item.id} value={String(item.id)} text={item.name} title={item.name}>
                    <span>{item.name}</span>
                  </Option>
                ))}
            </Dropdown>
          )}
        </Row>
      </Field>
    )
  },
)

Team.displayName = 'Team'

export const useStyles = makeStyles({
  dropdown: {
    fontSize: tokens.fontSizeBase300,
  },
})
