import { ApiClient } from '../../client/ApiClient'
import { DashApiOptions } from '../../DashApiOptions'
import { CopilotDashApiClient } from './CopilotDashApiClient'
import {
  ApiV2SymptomReportResponse,
  ApiV2Ticket,
  apiV2TicketSchema,
  ApiAISearchRequestPrams,
  ApiAISearchRequestBody,
  ApiAISearchResponse,
  apiAISearchResponseSchema,
} from './types'
import { apiV2SymptomReportResponseSchema } from './types/ApiV2SymptomReportResponse.schema'
import { Caches } from '../../client/ApiClientCaches'

/**
 * Code:
 * https://dev.azure.com/msasg/Falcon/_git/DSS?path=/services/LogCollector/src/Service/Api/LogCollector/V1/LogCollectorService.bond&_a=contents&version=GBmaster
 *
 * Swagger:
 * https://copilotdashwebservice-service.namcentral240175.copilot-dash-webservice.centralus-test.cosmic-int.office.net/swagger/index.html
 */
export class CopilotDashApi {
  private readonly client: ApiClient

  constructor(options: DashApiOptions) {
    this.client = new CopilotDashApiClient(options)
  }

  getTicket(ticketId: string): Promise<ApiV2Ticket> {
    const encodedTicketId = encodeURIComponent(ticketId)

    return this.client.get(`/api/v2/tickets/${encodedTicketId}`, {
      schema: apiV2TicketSchema,
    })
  }

  getTicketSymptomReport(ticketId: string): Promise<ApiV2SymptomReportResponse> {
    const encodedTicketId = encodeURIComponent(ticketId)

    return this.client.get(`/api/v2/tickets/${encodedTicketId}/SymptomReports`, {
      schema: apiV2SymptomReportResponseSchema,
    })
  }

  getAISearchTicket(query: ApiAISearchRequestPrams, data: ApiAISearchRequestBody): Promise<ApiAISearchResponse> {
    return this.client.post(`/api/v2/ticket/search`, {
      params: query,
      data: data,
      schema: apiAISearchResponseSchema,
      cache: Caches.SHORT,
    })
  }
}
