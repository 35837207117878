import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Column, Row, Spacer } from '../../../../../../../components/Layout'
import { Button, Checkbox, CheckboxProps, mergeClasses } from '@fluentui/react-components'
import { isNil } from 'lodash'
import { useUserSetting } from '@copilot-dash/settings'
import { AllSearchTableColumns } from '../../../../../../../components/TicketTableColumnConfigs'
import { useSearchScreenActions, useSearchScreenState } from '../../../../../store'
import { ResizableColumnsTable } from '../../../../../../../components/Table/ResizableColumnsTable'
import { SearchRoute } from '../../../../../../../router'
import { ColumnKey, INewTicketData, SearchTextPrefixType } from '@copilot-dash/domain'
import { LoaderComponent } from '../../../../loader/LoaderComponent'
import { useStyles } from './IssueTickets.styles'
import { GroupedTicketsByState } from './GroupedTicketsByState'
import { GroupedTicketsByTeam } from './GroupedTicketsByTeam'
import { ColumnSettingButton } from '../../../../../../../components/Button/ColumnSettingButton'
import { ColumnRegular } from '@fluentui/react-icons'

export interface IssueTicketsProps {
  onTicketsLoaded?: (tickets: INewTicketData[]) => void
  expectedTicketCount?: number
}

enum TableType {
  'All',
  'DSATsStatus',
  'AssignedTeam',
}

export const IssueTickets = memo(function IssueTickets({
  onTicketsLoaded,
  expectedTicketCount = 500,
}: IssueTicketsProps) {
  const styles = useStyles()
  const actions = useSearchScreenActions()
  const ticketId = useSearchScreenState((state) => state.clickedTicketInfo?.ticketId)

  const [activeTable, setActiveTable] = useState<TableType>(TableType.All)
  const [loading, setLoading] = useState(true)
  const [count, setCount] = useState<number | undefined>()
  const [hasMore, setHasMore] = useState(true)
  const [onlyShowVIPDSATs, setOnlyShowVIPDSATs] = useState<CheckboxProps['checked']>(false)
  const [tableItems, setTableItems] = useState<INewTicketData[]>([])
  const args = SearchRoute.navigator.useArgsOptional()
  const issueId = args?.issueId
  const batchId = args?.issueBatchId

  const [columns] = useUserSetting('feedbackInsightsTopIssueDetailsColumns')
  const selectedColumns = useMemo(() => {
    const allColumns = AllSearchTableColumns(SearchTextPrefixType.All)
    return columns
      .map((column) => allColumns.find((c) => c.columnId === column)!)
      .filter((c) => c)
      .filter((c) => (onlyShowVIPDSATs ? true : c.columnId !== ColumnKey.UserProfile))
  }, [columns, onlyShowVIPDSATs])

  useEffect(() => {
    actions.updateColumnSettingPanelVisibility(false)
  }, [actions])

  const loadTickets = useCallback(
    (offset = 0) => {
      if (issueId && batchId && expectedTicketCount > 0) {
        offset === 0 && setLoading(true)
        const query = SearchRoute.navigator.getArgsOptional()
        app.store.actions
          .searchTickets({
            product: query?.product || 'M365Chat',
            defaultRange: undefined,
            clusteringIssueId: issueId,
            batchId: batchId,
            count: expectedTicketCount,
            offset: offset,
            isAIF: false,
            teamId: 0,
            ticketStatus: 'TopIssue',
            customerTypes: onlyShowVIPDSATs ? ['VIP Power User'] : undefined,
          })
          .then((resp) => {
            setHasMore(resp.hasMore)
            setCount(resp.ticketCount)

            setTableItems((pre) => {
              const data = offset > 0 ? [...pre, ...resp.tickets] : resp.tickets
              if (!onlyShowVIPDSATs) {
                onTicketsLoaded?.(data)
              }
              return data
            })
          })
          .finally(() => {
            offset === 0 && setLoading(false)
          })
      }
    },
    [batchId, expectedTicketCount, issueId, onTicketsLoaded, onlyShowVIPDSATs],
  )

  useEffect(() => {
    if (issueId && batchId) {
      loadTickets()
    }
  }, [batchId, issueId, loadTickets, onlyShowVIPDSATs])

  const table = useMemo(() => {
    if (tableItems.length === 0) {
      return (
        <Column fill hAlign="center">
          No tickets found
        </Column>
      )
    }
    switch (activeTable) {
      case TableType.All:
        return (
          <ResizableColumnsTable
            items={tableItems}
            columns={selectedColumns}
            keyPicker={(data) => data.ticketId}
            supportLoadMore={hasMore}
            loadMoreFunction={loadTickets}
            enableColumnResizing={true}
            rowClick={actions.rowClick}
            selectedRowId={ticketId}
          />
        )
      case TableType.DSATsStatus:
        return <GroupedTicketsByState tickets={tableItems} hasMore={hasMore} loadMore={loadTickets} />
      case TableType.AssignedTeam:
        return <GroupedTicketsByTeam tickets={tableItems} hasMore={hasMore} loadMore={loadTickets} />
    }
  }, [actions.rowClick, activeTable, hasMore, loadTickets, selectedColumns, tableItems, ticketId])

  return (
    <Column>
      <Row>
        <Button
          shape="circular"
          className={mergeClasses(activeTable === TableType.All ? styles.activeBtn : styles.btn)}
          onClick={() => setActiveTable(TableType.All)}
        >
          All DSATs {isNil(count) ? null : `(${count})`}
        </Button>
        <Spacer width={8} />
        <Button
          shape="circular"
          className={mergeClasses(activeTable === TableType.DSATsStatus ? styles.activeBtn : styles.btn)}
          onClick={() => setActiveTable(TableType.DSATsStatus)}
        >
          DSAT State
        </Button>
        <Spacer width={8} />
        <Button
          shape="circular"
          className={mergeClasses(activeTable === TableType.AssignedTeam ? styles.activeBtn : styles.btn)}
          onClick={() => setActiveTable(TableType.AssignedTeam)}
        >
          Assigned Team
        </Button>
        <Spacer />
        <Checkbox
          label="Show VIP DSATs Only"
          checked={onlyShowVIPDSATs}
          onChange={(_, data) => {
            setOnlyShowVIPDSATs(data.checked)
            setTableItems([])
          }}
        />
        <ColumnSettingButton
          placeholder="Columns"
          apperance="transparent"
          icon={<ColumnRegular />}
          className={styles.columnSetting}
          onClickButton={actions.handleColumnSettingButtonClick}
        />
      </Row>
      <Spacer height={24} />
      <Row>
        {loading ? (
          <Column vAlign="center" fill>
            <Spacer height={50} />
            <LoaderComponent />
          </Column>
        ) : (
          table
        )}
      </Row>
    </Column>
  )
})
