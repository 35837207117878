import { ColumnKey, INewTicketData, SearchTextPrefixType } from '@copilot-dash/domain'
import { memo, useMemo, useState } from 'react'
import { Text } from '@fluentui/react-components'
import { Column, Row, Spacer } from '../../../../../../../components/Layout'
import { useUserSetting } from '@copilot-dash/settings'
import { AllSearchTableColumns } from '../../../../../../../components/TicketTableColumnConfigs'
import { ResizableColumnsTable } from '../../../../../../../components/Table/ResizableColumnsTable'
import { useSearchScreenActions, useSearchScreenState } from '../../../../../store'
import { motion } from 'framer-motion'
import { useStyles } from './CollapsibleTable.styles'
import { ChevronRightFilled } from '@fluentui/react-icons'

interface CollapsibleTableProps {
  tickets: INewTicketData[]
  title: string
  defaultExpanded?: boolean
  onlyShowVIPDSATs?: boolean
  hasMore?: boolean
  loadMore?: (offset: number) => void
}

export const CollapsibleTable = memo(function CollapsibleTable({
  tickets,
  title,
  defaultExpanded = false,
  onlyShowVIPDSATs = false,
  hasMore,
  loadMore,
}: CollapsibleTableProps) {
  const styles = useStyles()
  const actions = useSearchScreenActions()
  const ticketId = useSearchScreenState((state) => state.clickedTicketInfo?.ticketId)
  const [expanded, setExpanded] = useState(defaultExpanded)

  const [columns] = useUserSetting('feedbackInsightsTopIssueDetailsColumns')
  const selectedColumns = useMemo(() => {
    const allColumns = AllSearchTableColumns(SearchTextPrefixType.All)
    return columns
      .map((column) => allColumns.find((c) => c.columnId === column)!)
      .filter((c) => c)
      .filter((c) => (onlyShowVIPDSATs ? true : c.columnId !== ColumnKey.UserProfile))
  }, [columns, onlyShowVIPDSATs])

  const count = tickets.length
  const countStr = count === 0 ? '' : ` (${count})`

  return (
    <Column fill className={styles.container}>
      <Row
        className={styles.header}
        onClick={() => {
          setExpanded((bool) => !bool)
        }}
      >
        <Text weight="semibold" size={400}>
          {title}
          {countStr}
        </Text>
        <Spacer />
        <motion.div className={styles.icon} animate={{ rotate: expanded ? 90 : 0 }}>
          <ChevronRightFilled fontSize={22} />
        </motion.div>
      </Row>
      <motion.div
        layout
        animate={expanded ? 'expanded' : 'collapsed'}
        className={styles.accordionContent}
        variants={{
          collapsed: { height: 0, paddingTop: 0 },
          expanded: { height: 'auto', paddingTop: 16 },
        }}
        initial={false}
      >
        <ResizableColumnsTable
          items={tickets}
          columns={selectedColumns}
          keyPicker={(data) => data.ticketId}
          supportLoadMore={hasMore}
          loadMoreFunction={loadMore}
          enableColumnResizing={true}
          rowClick={actions.rowClick}
          selectedRowId={ticketId}
        />
      </motion.div>
    </Column>
  )
})
