import { ApiOds3sOfflineDataGroup3 } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getBlobMetadata } from '../actions-blob/getBlobMetadata'
import { getTicketSession } from '../actions-ticket/getTicketSession'
import { validateTicket3sOfflineDataTimeliness } from '../validators/validateTicket3sOfflineData'
import { getRaw3sOfflineIndex } from './getRaw3sOfflineIndex'

export function getRaw3sOfflineDataGroup3(
  context: IDashStoreContext,
  ticketId: string,
  turnId: string,
): PromiseSnapshot<ApiOds3sOfflineDataGroup3> {
  return context.getOrFetch<ApiOds3sOfflineDataGroup3>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[turnId]?.raw3sOfflineDataGroup3
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[turnId] ??= {})
      turn.raw3sOfflineDataGroup3 = snapshot
    },
    fetch: async () => {
      // Get turn index
      const index = await getRaw3sOfflineIndex(context, ticketId, turnId)

      // Get turn file
      const files = await getBlobMetadata(context, ticketId).promise
      const file = files.sssOffline.find((file) => file.index === index && file.group === 'Group3')
      if (!file) {
        // Validate offline data timeliness
        const session = await getTicketSession(context, ticketId).promise
        validateTicket3sOfflineDataTimeliness(ticketId, session.feedbackTime)

        throw TicketError.create('No3sOffline', { ticketId, ticketMessageId: turnId })
      }

      return await context.api.ods.get3sOfflineDataGroup3(file.fileUrl)
    },
  })
}
