import { TimeRange } from '@copilot-dash/core'
import { DefaultProductChannelMapping, ProductNames, TeamViewSubMenuIds, IQuery } from '@copilot-dash/domain'
import { TelemetryScope } from '@copilot-dash/logger'
import {
  BasicSearchTicketArgsNames,
  ODSSearchTicketArgsNames,
  TagMetaDataSearchTicketArgsNames,
} from '@copilot-dash/store'
import { pick } from 'lodash'
import * as React from 'react'
import { ITeamViewRouteArgs, TeamRoute } from '../../router'
import { useGlobalStore } from '../../store'
import { ALL_TEAMS } from '../../store/data/team/createTeamStore'
import { TeamScreenLayout } from './layout/TeamScreenLayout'
import { useTeamViewStore } from './store'
import { CategoryTypeIds } from './store/const'

interface ITeamScreenProps {
  args: ITeamViewRouteArgs
  currentQuery?: IQuery
}

export const TeamScreen: React.FC<ITeamScreenProps> = React.memo(({ args, currentQuery }) => {
  const argsRef = React.useRef(args)
  argsRef.current = args
  const defaultRange: TimeRange = {
    type: 'relative',
    value: 30,
    unit: 'days',
  }
  const products = application.store.use.getProducts()
  const teamListFetchStatus = useGlobalStore((state) => state.team.teamList.status)

  React.useEffect(() => {
    useTeamViewStore.getState().route.setArgs(args)
  }, [args])

  React.useEffect(() => {
    useTeamViewStore.getState().setCurrentQuery(currentQuery)
  }, [currentQuery])

  React.useEffect(() => {
    useTeamViewStore.getState().teams.setProduct(args.product)
  }, [args.product])

  React.useEffect(() => {
    useTeamViewStore.getState().toggleFilterPanel(false)
  }, [args.product, args.subMenu, args.teamName, args.issueId])

  React.useEffect(() => {
    const { teamName, issueId, vsoAccount, subMenu } = args
    const filters = pick(args, [
      ...Object.values({
        ...BasicSearchTicketArgsNames,
        ...TagMetaDataSearchTicketArgsNames,
        ...ODSSearchTicketArgsNames,
      }),
      'queryId',
    ])

    if (teamName && teamListFetchStatus === 'done') {
      useTeamViewStore.getState().teams.setLastSelectedTeam(teamName)
      useTeamViewStore.getState().rootCauses.fetchTeamRootCauses(teamName)
      useTeamViewStore.getState().toggleTicketDetail(false)
      useTeamViewStore.getState().toggleColumnSetting(false)
      useTeamViewStore.getState().toggleBatchUpdatePanel(false)
      useTeamViewStore.getState().tickets.setFilterForm(filters)
      useTeamViewStore.getState().setSelectedTicket(null)

      if (subMenu === TeamViewSubMenuIds.TopIssues) {
        useTeamViewStore.getState().tickets.setTicketsTabType('category')
        useTeamViewStore.getState().teams.setSelectedSubMenu(TeamViewSubMenuIds.TopIssues)
        useTeamViewStore.getState().rootCauses.setSelectedRootCause(null)
      } else if (subMenu === TeamViewSubMenuIds.RootCauses) {
        useTeamViewStore.getState().tickets.setTicketsTabType('category')
        useTeamViewStore.getState().teams.setSelectedSubMenu(TeamViewSubMenuIds.RootCauses)
        useTeamViewStore.getState().rootCauses.setSelectedRootCause(null)
        if (issueId) {
          useTeamViewStore.getState().tickets.setTicketsTabType('rootCause')
          useTeamViewStore.getState().rootCauses.setSelectedRootCause(issueId, vsoAccount)
        }
      } else if (subMenu === TeamViewSubMenuIds.All) {
        useTeamViewStore.getState().tickets.setTicketsTabType('category')
        useTeamViewStore.getState().teams.setSelectedSubMenu(TeamViewSubMenuIds.All)
        useTeamViewStore.getState().rootCauses.setSelectedRootCause(null)
        useTeamViewStore.getState().tickets.fetchTicketListForAll(filters)
      } else if (subMenu === TeamViewSubMenuIds.UnRootCaused) {
        useTeamViewStore.getState().tickets.setTicketsTabType('category')
        useTeamViewStore.getState().teams.setSelectedSubMenu(TeamViewSubMenuIds.UnRootCaused)
        useTeamViewStore.getState().rootCauses.setSelectedRootCause(null)
        if (!filters.range) {
          useTeamViewStore.getState().tickets.setFilterForm({ ...filters, range: defaultRange })
        }
      } else if (subMenu === TeamViewSubMenuIds.SharedQueries) {
        useTeamViewStore.getState().teams.setSelectedSubMenu(TeamViewSubMenuIds.SharedQueries)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // NOTE: @Ethan - We need specific dependencies here to trigger search tickets. Cannot use args directly.

    args.teamName,
    args.ticketStatus,
    args.issueId,
    args.vsoAccount,
    args.product,
    args.agentTypes,
    args.appTypes,
    args.client,
    args.channel,
    args.customerTypes,
    args.ring,
    args.promptLanguages,
    args.email,
    args.userId,
    args.range,
    args.hasVerbatim,
    args.hasUserConsent,
    args.isCooked,
    args.isApology,
    args.searchText,
    args.searchTextPrefix,
    args.tenantIds,
    args.groundedPrompts,
    args.thumbs,
    args.invocationType,
    args.triggeredSkill,
    args.hasCitation,
    args.hasEntityCard,
    args.hitAvalon,
    args.priority,
    args.isSTCAChina,
    args.hasErrorMessages,
    args.isTopi18N,
    args.responseHeroType,
    args.responseLinkType,
    args.semanticSearchType,
    args.refreshTicker,
    args.bizchatScenario,
    args.experienceType,
    args.hasConnector,
    args.hasMessageExtension,
    args.hasGPTExtension,
    args.hasCopilotExtensionIds,
    args.optionsSets,
    args.errorCode,
    args.isGCIntent,
    args.hasConnectorResult,
    args.dSATStatus,
    args.customTags,
    args.dSATAssignedTo,
    args.sliceIds,
    args.copilotExtensionIds,
    args.flights,

    args.subMenu,
    args.topIssueBatchId,

    teamListFetchStatus,
  ])

  const navigateByTeamName = React.useCallback(
    (teamName: string) => {
      if (teamName === ALL_TEAMS) {
        TeamRoute.navigator.navigate({
          product: argsRef.current.product,
          teamName: teamName,
          subMenu: TeamViewSubMenuIds.TopIssues,
          channel: argsRef.current.channel || DefaultProductChannelMapping[argsRef.current.product],
        })
      } else {
        TeamRoute.navigator.navigate({
          product: argsRef.current.product,
          teamName: teamName,
          subMenu: TeamViewSubMenuIds.UnRootCaused,
          ticketStatus: CategoryTypeIds.UnRootCaused,
          priority: args.product === ProductNames.M365Chat ? ['0', '1'] : undefined,
          channel: argsRef.current.channel || DefaultProductChannelMapping[argsRef.current.product],
        })
      }
    },
    [args.product],
  )

  React.useEffect(() => {
    useTeamViewStore.getState().teams.syncLastSelectedTeamFromStorage()
    const _lastSelectedTeam = useTeamViewStore.getState().teams.lastSelectedTeam
    if (!argsRef.current.teamName && _lastSelectedTeam) {
      // navigate to last selected team from storage
      navigateByTeamName(_lastSelectedTeam)
    } else if (!_lastSelectedTeam && argsRef.current.teamName) {
      // set last selected team from args
      useTeamViewStore.getState().teams.setLastSelectedTeam(argsRef.current.teamName)
    }
    const curProduct = products.data?.find((item) => item.name === args.product)

    if (!curProduct?.productId) return

    useGlobalStore
      .getState()
      .team.prepareTeamList(curProduct.productId)
      .then(() => {
        const teamList = useGlobalStore.getState().team.teamList.data ?? []
        // set first team as the last selected team if no last selected team
        if (
          teamList[0]?.name &&
          !teamList.find((team) => team.name === useTeamViewStore.getState().teams.lastSelectedTeam)
        ) {
          useTeamViewStore.getState().teams.setLastSelectedTeam(teamList[0].name)
          navigateByTeamName(teamList[0].name)
        }
      })

    return () => {
      // reset team store
      useTeamViewStore.getState().toggleTicketDetail(false)
      useTeamViewStore.getState().toggleColumnSetting(false)
      useTeamViewStore.getState().toggleFilterPanel(false)
    }
  }, [navigateByTeamName, products, args.product])

  return (
    <TelemetryScope scope="TeamScreen">
      <TeamScreenLayout />
    </TelemetryScope>
  )
})

TeamScreen.displayName = 'TeamScreen'
