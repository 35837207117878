import { GetTagValueTextFunction } from './GetTagValueTextFunction'
import { ITagDefinition } from './ITagDefinition.type'
import { TruncateTagFunction } from './TruncateTagFunction'
import { useSearchScreenState } from '../../store'

export const AppTypesTagConfig = (): ITagDefinition => {
  const form = useSearchScreenState((state) => state.form)
  const valueTexts = GetTagValueTextFunction('Customized tag', '', form.appTypes || [], 'AgentAppType:')

  return {
    key: 'appTypes',
    tagName: 'App Types',
    toolTipContentValue: valueTexts.join(', '),
    truncatedTagValue: TruncateTagFunction(valueTexts),
    IsShowTag: false,
    isTagClearable: true,
  }
}
