import * as React from 'react'
import {
  TagPicker,
  TagPickerList,
  TagPickerInput,
  TagPickerControl,
  TagPickerProps,
  TagPickerOption,
  TagPickerGroup,
  Spinner,
  Tag,
  Field,
  makeStyles,
  tokens,
  useTagPickerFilter,
  Text,
} from '@fluentui/react-components'
import { useTeamViewStore } from '../../screens/team/store'
import { UserDisplayName } from '../User/UserDisplayName'
import { UserPhoto } from '../User/UserPhoto'
import { useDebounce } from '../../hooks/useDebounce'

interface IProps {
  emailAddress: string | undefined
  onChangeUser: (value: string | undefined) => void
}

export const EmailTagPickerFilter = ({ emailAddress, onChangeUser }: IProps) => {
  const [selectedOption, setSelectedOption] = React.useState<string | undefined>()
  const selectedOptions = React.useMemo(() => (selectedOption ? [selectedOption] : []), [selectedOption])
  const productId = useTeamViewStore((state) => state.computed.productId)

  const teamId = useTeamViewStore((state) => state.teams.computed.selectedTeamId)
  const [options, setOptions] = React.useState<string[]>([])
  const [loading, setLoading] = React.useState(false)
  const [opened, setOpened] = React.useState(false)
  const [query, setQuery] = React.useState<string>('')

  const styles = useStyles()

  React.useEffect(() => {
    setSelectedOption(emailAddress)
  }, [emailAddress])

  const debouncedQuery = useDebounce(query, 300)

  React.useEffect(() => {
    if (debouncedQuery.trim().length > 0) {
      setQuery(debouncedQuery.trim())
    }
  }, [debouncedQuery])

  const onOptionSelect: TagPickerProps['onOptionSelect'] = (e, data) => {
    if (data.value === 'no-matches') {
      return
    }
    if (data.selectedOptions.length > 0) {
      onChangeUser(data.value)
    } else {
      onChangeUser(undefined)
    }
    setSelectedOption(selectedOption === data.value ? undefined : data.value)
    setQuery('')
  }

  function isValidEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const onOpenChange: TagPickerProps['onOpenChange'] = async (e, data) => {
    setOpened(data.open)
    if (data.open) {
      setLoading(true)
      if (productId && teamId) {
        const res = await application.store.actions.getAssignedTeamMembers(productId, teamId)
        const validEmail =
          res.assignedTeamMembers.length > 0 && res.assignedTeamMembers.filter((item) => isValidEmail(item))
        validEmail && setOptions(validEmail)
        setLoading(false)
      }
    }
  }

  const children = useTagPickerFilter({
    query,
    options,
    noOptionsElement: <TagPickerOption value="no-matches">No results found</TagPickerOption>,
    renderOption: (option) => (
      <TagPickerOption key={option} media={<UserPhoto userId={option} className={styles.avatar} />} value={option}>
        {option}
      </TagPickerOption>
    ),

    filter: (option) => !selectedOptions.includes(option) && option.toLowerCase().includes(query.toLowerCase()),
  })

  return (
    <Field style={{ maxWidth: 400 }}>
      <TagPicker onOptionSelect={onOptionSelect} selectedOptions={selectedOptions} onOpenChange={onOpenChange}>
        <TagPickerControl>
          {selectedOption ? (
            <TagPickerGroup aria-label="Selected Employees">
              <Tag
                key={selectedOption}
                media={<UserPhoto userId={selectedOption} className={styles.avatar} />}
                value={selectedOption}
              >
                <UserDisplayName userId={selectedOption} className={styles.displayName} />
              </Tag>
            </TagPickerGroup>
          ) : opened || query ? null : (
            <Text style={{ color: tokens.colorNeutralForeground4 }}>Select</Text>
          )}

          <TagPickerInput aria-label="Select Employees" value={query} onChange={(e) => setQuery(e.target.value)} />
        </TagPickerControl>
        <TagPickerList className={styles.optionList}>
          {options.length === 0 ? (
            loading ? (
              <Spinner size="tiny" style={{ height: 100 }} />
            ) : (
              <TagPickerOption value="no-matches">No results found</TagPickerOption>
            )
          ) : (
            <>{children}</>
          )}
        </TagPickerList>
      </TagPicker>
    </Field>
  )
}

const useStyles = makeStyles({
  avatar: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    fontSize: tokens.fontSizeBase200,
  },
  displayName: {
    maxWidth: '130px',
    fontSize: tokens.fontSizeBase300,
  },

  optionList: {
    maxHeight: '50vh',
  },
})
