import {
  DashSettingEndpoint,
  DefaultCustomizedFilter,
  DefaultFeedbackInsightsTopIssueDetailsColumnIds,
  DefaultFeedbackInsightsVipDSATsColumnIds,
  DefaultSearchScreenAIFColumnIds,
  DefaultSearchScreenNonAIFColumnIds,
  DefaultTeamViewColumnIds,
  searchFilterFieldNamesSchema,
  columnKeySchema,
  themeModeSchema,
} from '@copilot-dash/domain'
import { ZodType, z } from 'zod'
import { DashSettingStore } from './framework/DashSettingStore'
import { IDashSettingStore } from './framework/IDashSettingStore'
import { IDashSettingsForUser } from './IDashSettingsForUser'

export function createDashSettingsForUser(): IDashSettingsForUser {
  return {
    themeMode: create({
      key: 'theme_mode',
      schema: themeModeSchema,
      def: 'light',
    }),

    endpointUrl: create({
      key: 'endpoint_url',
      schema: z.nativeEnum(DashSettingEndpoint).nullable(),
      def: null,
    }),

    timeZone: create({
      key: 'time_zone',
      schema: z.string(),
      def: 'UTC',
    }),
    privacyModeEnabled: create({
      key: 'privacyMode_Enabled',
      schema: z.boolean(),
      def: false,
    }),

    searchScreenNonAIFColumns: create({
      key: 'searchscreen_non_aif_column_setting',
      schema: z.array(columnKeySchema),
      def: DefaultSearchScreenNonAIFColumnIds,
    }),

    searchScreenAIFColumns: create({
      key: 'searchscreen_aif_column_setting',
      schema: z.array(columnKeySchema),
      def: DefaultSearchScreenAIFColumnIds,
    }),

    feedbackInsightsVipDSATsColumns: create({
      key: 'feedback_insights_vip_dsats_column_setting',
      schema: z.array(columnKeySchema),
      def: DefaultFeedbackInsightsVipDSATsColumnIds,
    }),

    feedbackInsightsTopIssueDetailsColumns: create({
      key: 'top_issue_details_column_setting',
      schema: z.array(columnKeySchema),
      def: DefaultFeedbackInsightsTopIssueDetailsColumnIds,
    }),

    teamViewColumns: create({
      key: 'teamview_column_setting',
      schema: z.array(columnKeySchema),
      def: DefaultTeamViewColumnIds,
    }),

    teamViewCustomizedFilterItems: create({
      key: 'teamview_customized_filter_items',
      schema: z.array(searchFilterFieldNamesSchema),
      def: DefaultCustomizedFilter,
    }),

    searchScreenCustomizedFilterItems: create({
      key: 'searchscreen_customized_filter_items',
      schema: z.array(searchFilterFieldNamesSchema),
      def: DefaultCustomizedFilter,
    }),

    sharedQueriesTeamId: create({
      key: 'shared_queries_team_id',
      schema: z.record(z.union([z.number(), z.undefined()])),
      def: {},
    }),

    ticketContextDrawerWidth: create({
      key: 'ticket_context_drawer_width',
      schema: z.number().optional(),
      def: undefined,
    }),
    discussionPanelDrawerWidth: create({
      key: 'discussion_panel_drawer_width',
      schema: z.number().optional(),
      def: 640,
    }),
    activityHistoryDrawerWidth: create({
      key: 'activity_history_drawer_width',
      schema: z.number().optional(),
      def: 640,
    }),
  }

  function create<T>(params: { key: string; schema: ZodType<T>; def: T }): IDashSettingStore<T> {
    return new DashSettingStore({
      key: 'dash_setting_' + params.key,
      defValue: params.def,
      schema: params.schema,
    })
  }
}
