import { DashApi } from '@copilot-dash/api'
import { IDashEnvironment } from '@copilot-dash/core'
import { createDashStoreActions } from './createDashStoreActions'
import { createDashStoreContext } from './createDashStoreContext'
import { createDashStoreUse } from './createDashStoreUse'
import { createDashStoreUseHooks } from './createDashStoreUseHooks'
import { DefaultDashStoreState } from './DefaultDashStoreState'
import { IDashStore } from './IDashStore'

interface IParams {
  readonly env: IDashEnvironment
  readonly api: DashApi
}

export function createDashStore({ env, api }: IParams): IDashStore {
  const use = createDashStoreUse(DefaultDashStoreState)
  const context = createDashStoreContext({ env, use, api })
  const useHooks = createDashStoreUseHooks(context)
  const actions = createDashStoreActions(context)

  return {
    use: Object.assign(use, useHooks),
    get state() {
      return use.getState()
    },
    get actions() {
      return actions
    },
  }
}
