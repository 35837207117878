import { ApiOds3sOnlineDataGroup2Item } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { IKustoGwsLogItem, ITicketTurnHealthData, ITicketTurnTransactionHealthData } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { get3sGwsLog } from '../actions-kusto/get3sGwsLog'
import { getRaw3sOnlineDataGroup2 } from '../actions-raw-3s/getRaw3sOnlineDataGroup2'
import { getTicket } from '../actions-ticket/getTicket'
import { getTicketSession } from '../actions-ticket/getTicketSession'

export function getTicketHealthData(
  context: IDashStoreContext,
  ticketId: string,
  turnId: string,
): PromiseSnapshot<ITicketTurnHealthData> {
  return context.getOrFetch<ITicketTurnHealthData>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[turnId]?.health
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[turnId] ??= {})
      turn.health = snapshot
    },
    fetch,
  })

  async function fetch(): Promise<ITicketTurnHealthData> {
    const ticket = await getTicket(context, ticketId).promise
    if (!ticket.tag.sssTriggered) {
      throw TicketError.create('No3SDueToNotTriggered', { ticketId })
    }

    // From GWS Logs
    const results: Record<string, ITicketTurnTransactionHealthData> = {}
    const logs = await get3sGwsLog(context, ticketId, turnId).promise
    for (const item of logs) {
      if (item.httpStatusCode === '500' && item.transactionId) {
        results[item.transactionId] = getHealthItemData(item, item.transactionId, undefined, 'GWS log')
      }
    }

    // From 3s online data
    const session = await getTicketSession(context, ticketId).promise
    const interaction = session.interactions.find((item) => item.messageId === turnId)
    const onlineGroup2 = await getRaw3sOnlineDataGroup2(context, ticketId, turnId).promise
    const onlineData = interaction?.sssOnlineDataList ?? []

    onlineData.forEach((item, index) => {
      const online = onlineGroup2[index]
      if (item.transactionId) {
        results[item.transactionId] = getHealthItemData(
          logs.find((log) => log.transactionId === item.transactionId),
          item.transactionId,
          online,
          '3S online data',
        )
      }
    })

    return results
  }

  function getHealthItemData(
    fullGWSLog: IKustoGwsLogItem | undefined,
    transactionId: string,
    data: ApiOds3sOnlineDataGroup2Item | undefined,
    source: string,
  ): ITicketTurnTransactionHealthData {
    const newDiagnosticData: Record<string, unknown> = {}
    if (fullGWSLog) {
      const diagnosticData = fullGWSLog.diagnosticDataCustomized
      Object.keys(diagnosticData ?? {}).forEach((key) => {
        if (
          key.startsWith('ShouldExecuteDomain') &&
          diagnosticData?.[key]?.toString().includes('ShouldExecute: True')
        ) {
          newDiagnosticData[key] = diagnosticData[key]
        }
      })
      if (diagnosticData?.PeopleNaturalSearchAnswerWorkflow) {
        newDiagnosticData['PeopleNaturalSearchAnswerWorkflow'] = diagnosticData?.PeopleNaturalSearchAnswerWorkflow
      }
    }

    const query =
      data?.ReplayRequest?.AnswerEntityRequests?.[0]?.Query?.DisplayQueryString ||
      data?.ReplayRequest?.AnswerEntityRequests?.[0]?.Query?.QueryString ||
      data?.ReplayRequest?.EntityRequests?.[0]?.Query?.DisplayQueryString ||
      data?.ReplayRequest?.EntityRequests?.[0]?.Query?.QueryString

    const lu = data?.ReplayResponse?.TrimmedResponse?.ExtendedData?.Info

    return {
      transactionId,
      source,
      httpStatusCode: fullGWSLog?.httpStatusCode,
      query,
      lu,
      response: {
        entitySets: '',
        answerEntitySets: '',
      },
      gwsLog: {
        diagnosticData: newDiagnosticData,
        responseMetaJson: fullGWSLog?.responseMetaJsonDecoded,
      },
    }
  }
}
