import {
  AllSupportedAIFColumns,
  AllSupportedNonAIFColumns,
  getProductIdByName,
  INewTicketData,
  IUpdateTicketStatusInfoData,
  NonRemovableColumns,
  overViewTabsSchema,
  SearchTextPrefixType,
} from '@copilot-dash/domain'
import { useUserSetting } from '@copilot-dash/settings'
import { SelectTabData, SelectTabEvent } from '@fluentui/react-components'
import { motion } from 'framer-motion'
import { compact } from 'lodash'
import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { COLUMN_SETTING_PANEL_WIDTH } from '../../../components/ColumnSettingPanel/children/ColumnSettingPanelBody.styles'
import { ColumnSettingPanel } from '../../../components/ColumnSettingPanel/ColumnSettingPanel'
import { Column, Row, Spacer, Body, Right } from '../../../components/Layout'
import { ExtendedTableColumnDefinition } from '../../../components/Table/ExtendedTableColumn'
import { ITicketFilterFormRef } from '../../../components/TicketsFilterPanel/children/TicketFilterForm/TicketFilterForm.types'
import { FILTER_PANEL_WIDTH } from '../../../components/TicketsFilterPanel/layout/TicketsFilterPanelLayout.styles'
import { TICKET_SUMMARY_PANEL_WIDTH } from '../../../components/TicketSummaryPanel/layout/TicketSummaryPanelLayout.styles'
import { TicketSummaryPanel } from '../../../components/TicketSummaryPanel/TicketSummaryPanel'
import { AllSearchTableColumns } from '../../../components/TicketTableColumnConfigs'
import { useToast } from '../../../hooks/useToast'
import { SearchRoute } from '../../../router'
import { useGlobalStore } from '../../../store'
import { IssueDetail } from '../children/feedbackInsights/issueDetail'
import { FilterPanel } from '../children/panel/FilterPanel'
import { SearchScreenBody } from '../children/SearchScreenBody'
import { useSearchScreenActions, useSearchScreenState } from '../store'
import { useStyles } from './SearchScreenLayout.styles'

export const SearchScreenLayout = memo(function SearchScreenLayout() {
  const styles = useStyles()
  const actions = useSearchScreenActions()
  const state = useSearchScreenState((state) => state)
  const form = state.form
  const [columnsNonAIF, setColumnsNonAIF] = useUserSetting('searchScreenNonAIFColumns')
  const [columnsAIF, setColumnsAIF] = useUserSetting('searchScreenAIFColumns')
  const [columnsVipDSATs, setColumnsVipDSATs] = useUserSetting('feedbackInsightsVipDSATsColumns')
  const [columnsTopIssueDetails, setColumnsTopIssueDetails] = useUserSetting('feedbackInsightsTopIssueDetailsColumns')
  const formRef = useRef<ITicketFilterFormRef | null>(null)
  const [selectedColumns, setSelectedColumns] = useState<ExtendedTableColumnDefinition<INewTicketData>[]>([])
  const focusedTab = useSearchScreenState((state) => state.focusedTab)
  const issueId = SearchRoute.navigator.useArgsOptional()?.issueId
  const issueBatchId = SearchRoute.navigator.useArgsOptional()?.issueBatchId

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setValues({
        ...form,
        range: form.range ?? form.defaultRange,
      })
    }
  }, [form])

  const toast = useToast()

  const handleAfterSaved = useCallback(
    (updatedInfo: IUpdateTicketStatusInfoData) => {
      actions.updateTicketStatus(updatedInfo.ticketId, updatedInfo)
    },
    [actions],
  )

  const handleSave = useCallback(
    (draft: IUpdateTicketStatusInfoData) => {
      return useGlobalStore
        .getState()
        .postTicketDetails(draft)
        .then((resp) => {
          if (resp.updateStatus === true) {
            handleAfterSaved(draft)
          } else {
            throw new Error(resp.errorMessage)
          }
        })
        .catch((err) => {
          toast.showError('Failed to save', err.message)
          throw err
        })
    },
    [toast, handleAfterSaved],
  )

  // Update selected columns based on the form
  useEffect(() => {
    const defaultColumns = AllSearchTableColumns(
      form.searchTextPrefix || SearchTextPrefixType.All,
      form.searchText,
      form.email,
      form.tenantIds,
    )
    if (form.product === 'TenantAdminFeedback') {
      const newSelectedColumns: ExtendedTableColumnDefinition<INewTicketData>[] = compact(
        columnsAIF.map((columnId) => defaultColumns.find((column) => column.columnId === columnId)),
      )
      setSelectedColumns(newSelectedColumns)
    } else {
      const newSelectedColumns: ExtendedTableColumnDefinition<INewTicketData>[] = compact(
        columnsNonAIF.map((columnId) => defaultColumns.find((column) => column.columnId === columnId)),
      )
      setSelectedColumns(newSelectedColumns)
    }
  }, [form.searchTextPrefix, form.searchText, form.email, form.product, columnsAIF, columnsNonAIF, form.tenantIds])

  const onTabSelect = (_: SelectTabEvent, data: SelectTabData) => {
    const value = overViewTabsSchema.safeParse(data.value).data
    if (value) {
      actions.updateFiltersPanelVisibility(false)
      actions.updateColumnSettingPanelVisibility(false)
      actions.updateFocusedTab(value)
    }
  }

  return (
    <motion.div
      layout
      animate={
        focusedTab === 'allFeedback' && state.isTicketsFilterPanelOpen
          ? 'filterOpen'
          : state.isColumnSettingPanelOpen
            ? 'columnSettingOpen'
            : state.isTicketSummaryPanelOpen
              ? 'ticketSummaryOpen'
              : 'close'
      }
      className={styles.framerContainer}
    >
      <Row fill>
        <Spacer width="32px" />
        <Body className={styles.body}>
          <Column>
            <Body>
              {issueId && issueBatchId ? (
                <IssueDetail issueId={issueId} issueBatchId={issueBatchId} />
              ) : (
                <SearchScreenBody
                  selectedColumns={selectedColumns}
                  selectedValue={focusedTab}
                  onTabSelect={onTabSelect}
                />
              )}
            </Body>
          </Column>
        </Body>
        <Spacer width="12px" />
        <motion.div
          layout
          variants={{
            close: { width: 0 },
            filterOpen: { width: FILTER_PANEL_WIDTH },
            columnSettingOpen: { width: COLUMN_SETTING_PANEL_WIDTH },
            ticketSummaryOpen: { width: TICKET_SUMMARY_PANEL_WIDTH },
          }}
          className={styles.framerPanel}
          initial={false}
        >
          <Right className={styles.right}>
            <Column fill>
              <FilterPanel />
              <ColumnSettingPanel
                isPanelOpen={state.isColumnSettingPanelOpen}
                onDismiss={() => actions.updateColumnSettingPanelVisibility(false)}
                NonRemovableColumns={NonRemovableColumns}
                AllSupportedColumns={
                  form.product === 'TenantAdminFeedback' ? AllSupportedAIFColumns : AllSupportedNonAIFColumns
                }
                selectedColumns={
                  focusedTab === 'feedbackInsights'
                    ? issueId
                      ? columnsTopIssueDetails
                      : columnsVipDSATs
                    : form.product === 'TenantAdminFeedback'
                      ? columnsAIF
                      : columnsNonAIF
                }
                setSelectedColumns={
                  focusedTab === 'feedbackInsights'
                    ? issueId
                      ? setColumnsTopIssueDetails
                      : setColumnsVipDSATs
                    : form.product === 'TenantAdminFeedback'
                      ? setColumnsAIF
                      : setColumnsNonAIF
                }
              />
              {state.clickedTicketInfo?.ticketId && (
                <TicketSummaryPanel
                  isTicketSummaryPanelOpen={state.isTicketSummaryPanelOpen}
                  onDismissTicketSummaryPanel={actions.onDismissTicketSummaryPanel}
                  ticketId={state.clickedTicketInfo.ticketId}
                  ticketInfo={state.clickedTicketInfo}
                  onSave={handleSave}
                  productId={getProductIdByName(form.product)}
                  afterSavedCallback={handleAfterSaved}
                />
              )}
            </Column>
          </Right>
        </motion.div>
      </Row>
    </motion.div>
  )
})
