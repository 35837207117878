import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Column, Row, Spacer } from '../../../../../components/Layout'
import { IssueTickets } from './children/issueTickets'
import { IssueDetails } from './children/issueDetails'
import { Progress } from './children/progress'
import { AssignedTeam } from './children/assignedTeam'
import { Divider, Text } from '@fluentui/react-components'
import { ArrowLeftRegular } from '@fluentui/react-icons'
import { useStyles } from './IssueDetail.styles'
import { useLocation } from 'react-router-dom'
import { INewTicketData, ITopIssueExtendInfo } from '@copilot-dash/domain'
import { SearchRoute } from '../../../../../router'
import { getRandomColor } from './children/components/Legend'
import { ErrorViewBoundary } from '../../../../../components/Error'
import { TelemetryScope } from '@copilot-dash/logger'

export interface IssueDetailProps {
  issueId: string
  issueBatchId: string
}

export const IssueDetail = memo(function IssueDetail(props: IssueDetailProps) {
  const { issueId, issueBatchId } = props
  const styles = useStyles()

  const location = useLocation()

  const [extendInfoLoading, setExtendInfoLoading] = useState(true)
  const [ticketsLoading, setTicketLoading] = useState(true)
  const [issueInfo, setIssueInfo] = useState<ITopIssueExtendInfo | undefined>()

  useEffect(() => {
    if (!issueInfo && location.state?.issueExtendInfo) {
      setIssueInfo(location.state.issueExtendInfo)
      setExtendInfoLoading(false)
    } else if (!issueInfo && issueBatchId && issueId) {
      application.store.actions.getTopIssueExtendInfo(issueBatchId, [issueId]).then((extendInfoMap) => {
        setIssueInfo(extendInfoMap[`${issueBatchId}-${issueId}`])
        setExtendInfoLoading(false)
      })
    }
  }, [issueInfo, location.state, issueBatchId, issueId])

  const issueTitle = issueInfo?.issueTitle
  const handleGoBack = useCallback(() => {
    if (history.length > 1) {
      history.back()
      return
    }
    const args = SearchRoute.navigator.getArgs()
    SearchRoute.navigator.navigate({
      product: args.product,
      tab: 'feedbackInsights',
      issueBatchId: issueBatchId,
    })
  }, [issueBatchId])

  const issueDetailData = useMemo(() => {
    return {
      priority: issueInfo?.priority ?? 0,
      DSATsCount: issueInfo?.ticketCount ?? 0,
      DSATsCoverage: issueInfo?.coverage ?? 0,
      vipUserIds: issueInfo?.VIPPowerUserIdList ?? [],
      tenants: issueInfo?.CAPTenantNameList ?? [],
    }
  }, [issueInfo])

  const progressData = useMemo(() => {
    return [
      {
        state: 'Closed' as const,
        count: issueInfo?.ticketStatusCount.closed ?? 0,
      },
      {
        state: 'Team Assigned' as const,
        count: issueInfo?.ticketStatusCount.teamAssigned ?? 0,
      },
      {
        state: 'Root Caused' as const,
        count: issueInfo?.ticketStatusCount.rootCaused ?? 0,
      },
      {
        state: 'Untriaged' as const,
        count:
          (issueInfo?.ticketCount ?? 0) -
          (issueInfo?.ticketStatusCount.closed ?? 0) -
          (issueInfo?.ticketStatusCount.teamAssigned ?? 0) -
          (issueInfo?.ticketStatusCount.rootCaused ?? 0),
      },
    ].filter((item) => item.count > 0)
  }, [issueInfo])

  const [assignedTeamData, setAssignedTeamData] = useState<{ name: string; count: number }[]>([])

  const handleTicketsLoaded = useCallback((tickets: INewTicketData[]) => {
    setTicketLoading(false)
    const tempData: { name: string; count: number }[] = []
    for (const ticket of tickets) {
      const teamName = ticket.teamArea
      const team = tempData.find((team) => team.name === teamName)
      if (team) {
        team.count++
      } else {
        teamName && tempData.push({ name: teamName, count: 1 })
      }
    }
    const sortedTeamsList = [...tempData].sort((a, b) => b.count - a.count)
    setAssignedTeamData(sortedTeamsList)
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const assignedTeamPredefinedColors = ['#3340C2', '#7281FF', '#515EF5', '#36a7e4', '#2c94cb']
  const assignedTeamBackgroundColors = useMemo(() => {
    return assignedTeamData.map((_, index) => assignedTeamPredefinedColors[index] || getRandomColor())
  }, [assignedTeamData, assignedTeamPredefinedColors])

  const items = useMemo(() => {
    return assignedTeamData.map((team, index) => ({
      color: assignedTeamBackgroundColors[index] || getRandomColor(),
      label: team.name,
    }))
  }, [assignedTeamData, assignedTeamBackgroundColors])
  const teamsWithColors = useMemo(() => {
    return assignedTeamData.map((team, index) => ({
      count: team.count,
      legendItem: items[index],
    }))
  }, [assignedTeamData, items])

  return (
    <ErrorViewBoundary label="TopIssueDetail">
      <TelemetryScope
        scope="TopIssueDetail"
        properties={{
          issueId,
          issueBatchId,
        }}
      >
        <Column className={styles.container}>
          <Row vAlign="center">
            <ArrowLeftRegular onClick={handleGoBack} fontSize={20} style={{ cursor: 'pointer' }} />
            <Spacer width={8} />
            <Text size={600} weight="semibold">
              {issueTitle}
            </Text>
          </Row>
          <Spacer height={16} />
          <Row className={styles.cardsContainer}>
            <Column className={styles.card}>
              <IssueDetails
                loading={extendInfoLoading}
                priority={issueDetailData.priority}
                DSATsCount={issueDetailData.DSATsCount}
                DSATsCoverage={issueDetailData.DSATsCoverage}
                vipUserIds={issueDetailData.vipUserIds}
                tenants={issueDetailData.tenants}
              />
            </Column>
            <Divider vertical className={styles.divider} />
            <Column className={styles.card}>
              <Progress loading={extendInfoLoading} stateList={progressData} />
            </Column>
            <Divider vertical className={styles.divider} />
            <Column className={styles.card}>
              <AssignedTeam loading={ticketsLoading} teamsWithColors={teamsWithColors} />
            </Column>
          </Row>
          <Spacer height={30} />
          <IssueTickets onTicketsLoaded={handleTicketsLoaded} expectedTicketCount={issueDetailData.DSATsCount} />
        </Column>
      </TelemetryScope>
    </ErrorViewBoundary>
  )
})
