import { EnableExceptionTracking } from '@copilot-dash/logger'
import { IDashStoreContext } from '../../IDashStoreContext'
import { NewSearchCopilotTicketsResult } from './NewSearchTicketsAction.types'
import { ISearchTicketOptions } from './SearchTicketAction.types'
import { NewTicketConverter } from './converters/NewTicketConverter'
import { BaseTicketSearchAction } from './BaseTicketSearchAction'

interface IParams {
  readonly searchId: string
  readonly utteranceOrResponseOdsSessionIdList: string[]
  readonly otherOdsSessionIdList: string[]
  readonly options: ISearchTicketOptions
  readonly odsSearchTriggered?: boolean
  readonly aiSearchTriggered?: boolean
}

export class SearchTicketWithIdListAction extends BaseTicketSearchAction {
  private readonly context: IDashStoreContext

  constructor(context: IDashStoreContext) {
    super()
    this.context = context
  }

  @EnableExceptionTracking()
  async search(params: IParams): Promise<NewSearchCopilotTicketsResult> {
    const request = this.createRequest(params)

    const response = await this.context.api.logCollector.newSearchCopilotTickets(request)

    return {
      searchId: params.searchId,
      ticketCount: response.ticketCount ?? response.tickets.length,
      tickets: response.tickets.map(NewTicketConverter.fromApiContext),
      hasMore: response.hasMore ?? false,
      errorMessage: response.errorMessage,
      satCount: response.sATCount,
      dsatCount: response.dSATCount,
    }
  }
}
