import { ApiSearchItems, ApiAISearchResponse, ApiV2Ring } from '@copilot-dash/api'
import { EnableExceptionTracking, Logger } from '@copilot-dash/logger'
import { IDashStoreContext } from '../../IDashStoreContext'
import { ISearchTicketOptions } from './SearchTicketAction.types'
import { Times, PromiseSnapshots } from '@copilot-dash/core'
import { SearchTextPrefixType, DefaultTriggeredSkillList, TicketRingType } from '@copilot-dash/domain'
import { IQueryBuilderArgs } from './SearchTicketIdByAISearchAction.types'
import { IDashStoreState } from '../../IDashStoreState'
export class SearchTicketIdByAISearchAction {
  private readonly context: IDashStoreContext

  constructor(context: IDashStoreContext) {
    this.context = context
  }

  @EnableExceptionTracking()
  async search(options: ISearchTicketOptions, searchId: string): Promise<Array<string>> {
    const startTime = Date.now()

    const params = this.prepareParam(options)

    const { searchQuery, filterQuery } = this.buildQuery(params)
    const searchPrams = {
      query: searchQuery,
    }
    const searchBody = {
      filter: filterQuery,
      skip: 0,
      size: 2000,
      //select: ['id'],
      orderBy: ['createDateTime desc'],
    }

    const response = await this.context.requestAnd({
      request: async () => {
        return await this.context.api.copilotDash.getAISearchTicket(searchPrams, searchBody)
      },
      onSuccess: (state: IDashStoreState, data: ApiAISearchResponse) => {
        data.searchItems.forEach((ticket: ApiSearchItems) => {
          const ticketId = ticket.id
          const stateTicket = state.tickets[ticketId] ?? {}

          state.tickets[ticketId] = {
            ...stateTicket,
            utterance: ticket.utterance ? PromiseSnapshots.done(ticket.utterance) : stateTicket.utterance,
            verbatim: ticket.verbatim ? PromiseSnapshots.done(ticket.verbatim) : stateTicket.verbatim,
            response: ticket.response ? PromiseSnapshots.done(ticket.response) : stateTicket.response,
            customTags: ticket.customTags ? PromiseSnapshots.done(ticket.customTags) : stateTicket.customTags,
          }
        })
      },
    })

    const duration = Date.now() - startTime
    Logger.telemetry.trackMetric('AiSearch', { duration, filters: options, searchId: searchId })

    return response.searchItems.map((ticket: ApiSearchItems) => ticket.id)
  }

  private prepareParam = (options: ISearchTicketOptions): IQueryBuilderArgs => {
    const { from, to } = Times.formatTimeRange(options.range ?? options.defaultRange, { timezone: 'UTC' })
    const triggeredSkills = options.triggeredSkill
      ?.map((val) => {
        const triggeredSkill = DefaultTriggeredSkillList.find((skill) => skill.key === val)
        return triggeredSkill ? triggeredSkill.options : []
      })
      .flat()

    const hasErrorMessagesConditions = options.hasErrorMessages
      ?.map((condition) => {
        return condition.includes('&') ? condition.split('&') : condition
      })
      .flat()

    const request: IQueryBuilderArgs = {
      userId: options.userId,
      tenantId: options.tenantIds,
      from: from,
      to: to,
      emotionType: options.thumbs?.length === 1 ? options.thumbs[0] : undefined,
      hasUserConsent:
        options.hasUserConsent?.length === 1 ? (options.hasUserConsent[0]?.startsWith('!') ? false : true) : undefined,
      clientName: options.client,
      scenarioName: options.channel,
      ring: options.ring?.map((ring: TicketRingType) => this.convertTicketRingTypeToApiV2Ring(ring)),

      verbatim:
        options.searchTextPrefix === SearchTextPrefixType.Verbatim ||
        options.searchTextPrefix === SearchTextPrefixType.All
          ? options.searchText
          : undefined,
      utterance:
        options.searchTextPrefix === SearchTextPrefixType.Utterance ||
        options.searchTextPrefix === SearchTextPrefixType.All
          ? options.searchText
          : undefined,
      response:
        options.searchTextPrefix === SearchTextPrefixType.Response ||
        options.searchTextPrefix === SearchTextPrefixType.All
          ? options.searchText
          : undefined,
      customTags: options.customTags,
      tags: [
        ...(options.promptLanguages && options.promptLanguages.length ? [options.promptLanguages.join('|')] : []),
        ...(options.groundedPrompts?.length ? [options.groundedPrompts.join('|')] : []),
        //...(options.hasUserConsent?.length ? [options.hasUserConsent.join('|')] : []),
        ...(options.isApology?.length ? [options.isApology.join('|')] : []),
        ...(options.hasVerbatim?.length ? [options.hasVerbatim.join('|')] : []),
        ...(options.customerTypes?.length ? [options.customerTypes.join('|')] : []),
        ...(options.invocationType?.length ? [options.invocationType.join('|')] : []),
        ...(triggeredSkills ?? []),
        ...(hasErrorMessagesConditions ?? []),
        ...(options.hasCitation?.length ? [options.hasCitation.join('|')] : []),
        ...(options.hasEntityCard?.length ? [options.hasEntityCard.join('|')] : []),
        ...(options.hitAvalon?.length ? [options.hitAvalon.join('|')] : []),
        ...(options.isSTCAChina?.length ? [options.isSTCAChina.join('|')] : []),
        ...(options.isTopi18N?.length ? [options.isTopi18N.join('|')] : []),
        ...(options.responseHeroType?.length ? [options.responseHeroType.join('|')] : []),
        ...(options.responseLinkType?.length ? [options.responseLinkType.join('|')] : []),
        ...(options.semanticSearchType?.length ? [options.semanticSearchType.join('|')] : []),
        ...(options.bizchatScenario?.length ? [options.bizchatScenario.join('|')] : []),
        ...(options.experienceType?.length ? [options.experienceType.join('|')] : []),
        ...(options.hasConnector?.length ? [options.hasConnector.join('|')] : []),
        ...(options.hasGPTExtension?.length ? [options.hasGPTExtension.join('|')] : []),
        ...(options.hasMessageExtension?.length ? [options.hasMessageExtension.join('|')] : []),
        ...(options.hasCopilotExtensionIds?.length ? [options.hasCopilotExtensionIds.join('|')] : []),
        ...(options.errorCode?.length ? [options.errorCode.join('|')] : []),
        ...(options.isGCIntent?.length ? [options.isGCIntent.join('|')] : []),
        ...(options.hasConnectorResult?.length ? [options.hasConnectorResult.join('|')] : []),
        ...(options.agentTypes?.length ? [options.agentTypes.join('|')] : []),
        ...(options.appTypes?.length ? [options.appTypes.join('|')] : []),
      ],
    }
    return request
  }

  private buildQuery(args: IQueryBuilderArgs): { searchQuery?: string; filterQuery: string } {
    const searchTerms: string[] = []
    const filters: string[] = []
    const addFilter = (condition: boolean, filter: string) => {
      if (condition) filters.push(filter)
    }

    // Add searchable fields to searchQuery
    if (args.utterance) searchTerms.push(`utterance:"${args.utterance}"`)
    if (args.verbatim) searchTerms.push(`verbatim:"${args.verbatim}"`)
    if (args.response) searchTerms.push(`response:"${args.response}"`)

    // Add other fields to filterQuery
    addFilter(!!args.userId, `userId eq '${args.userId}'`)
    addFilter(!!args.tenantId?.length, `(${args.tenantId?.map((id) => `tenantId eq '${id}'`).join(' or ')})`)
    addFilter(!!args.from, `createDateTime ge ${args.from}`)
    addFilter(!!args.to, `createDateTime le ${args.to}`)
    addFilter(!!args.emotionType, `emotionType eq '${args.emotionType}'`)
    addFilter(args.hasUserConsent !== undefined, `hasUserConsent eq ${args.hasUserConsent}`)
    addFilter(!!args.clientName?.length, `(${args.clientName?.map((name) => `clientName eq '${name}'`).join(' or ')})`)
    addFilter(
      !!args.scenarioName?.length,
      `(${args.scenarioName?.map((name) => `scenarioName eq '${name}'`).join(' or ')})`,
    )
    addFilter(!!args.ring?.length, `(${args.ring?.map((r) => `ring eq '${r}'`).join(' or ')})`)
    addFilter(!!args.customTags?.length, `(${args.customTags?.map((id) => `customTags eq '${id}'`).join(' or ')})`)
    //Tag Based Filter
    addFilter(
      !!args.tags?.length,
      `(${args.tags
        ?.map((tag) => {
          if (tag.startsWith('!')) {
            return `not tags/any(t: t eq '${tag.substring(1)}')`
          } else if (tag.includes('|')) {
            const orTags = tag
              .split('|')
              .map((t) => `tags/any(t: t eq '${t}')`)
              .join(' or ')
            return `(${orTags})`
          } else {
            return `tags/any(t: t eq '${tag}')`
          }
        })
        .join(' and ')})`,
    )

    const searchQuery = searchTerms.length > 0 ? searchTerms.join(' OR ') : undefined
    const filterQuery = filters.join(' and ')
    return { searchQuery, filterQuery }
  }

  private convertTicketRingTypeToApiV2Ring(ticketRingType: TicketRingType): ApiV2Ring {
    switch (ticketRingType) {
      case TicketRingType.MSIT:
        return ApiV2Ring.MSIT
      case TicketRingType.SDFV2:
        return ApiV2Ring.SDFv2
      case TicketRingType.SIP:
        return ApiV2Ring.SIP
      case TicketRingType.WW:
        return ApiV2Ring.WW
      case TicketRingType.DONMT:
        return ApiV2Ring.DONMT
      case TicketRingType.TDF:
        return ApiV2Ring.TDF
      default:
        return ApiV2Ring.Unknown
    }
  }
}
