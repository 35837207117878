import { ApiOds3sOnlineDataGroup2 } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getBlobMetadata } from '../actions-blob/getBlobMetadata'
import { getRaw3sOnlineIndex } from './getRaw3sOnlineIndex'

export function getRaw3sOnlineDataGroup2(
  context: IDashStoreContext,
  ticketId: string,
  turnId: string,
): PromiseSnapshot<ApiOds3sOnlineDataGroup2> {
  return context.getOrFetch<ApiOds3sOnlineDataGroup2>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[turnId]?.raw3sOnlineDataGroup2
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[turnId] ??= {})
      turn.raw3sOnlineDataGroup2 = snapshot
    },
    fetch: async () => {
      // Get turn index
      const index = await getRaw3sOnlineIndex(context, ticketId, turnId)

      // Get turn file
      const files = await getBlobMetadata(context, ticketId).promise
      const file = files.sssOnline.find((file) => file.index === index && file.group === 'Group2')
      if (!file) {
        throw TicketError.create('No3sOnline', { ticketId, ticketMessageId: turnId })
      }

      return await context.api.ods.get3sOnlineDataGroup2(file.fileUrl)
    },
  })
}
