import { INewTicketData } from '@copilot-dash/domain'
import { TelemetryScope } from '@copilot-dash/logger'
import { memo, useMemo } from 'react'
import { ErrorView } from '../../../../components/Error'
import { ExtendedTableColumnDefinition } from '../../../../components/Table/ExtendedTableColumn'
import { ResizableColumnsTable } from '../../../../components/Table/ResizableColumnsTable'
import { useSearchScreenActions, useSearchScreenState } from '../../store'
import { useStyles } from './SearchScreenTableView.styles'
import { useFeatureFlight } from '@copilot-dash/settings'
interface IProps {
  readonly tableItem: INewTicketData[]
  readonly selectedColumns: ExtendedTableColumnDefinition<INewTicketData>[]
  readonly keyPicker: (item: INewTicketData, index: number) => string
}

export const SearchScreenTableView = memo(({ tableItem, selectedColumns, keyPicker }: IProps) => {
  const styles = useStyles()
  const actions = useSearchScreenActions()
  const ticketId = useSearchScreenState((state) => state.clickedTicketInfo?.ticketId)
  const searchFeedbackResult = useSearchScreenState((state) => state.searchFeedbackResult)
  const searchSessionId = useSearchScreenState((state) => state.searchSessionId)
  const [isAISearchEnable] = useFeatureFlight('EnableAISearch') ?? false

  const loadMoreFunction = (offset: number) => {
    actions.loadMore(offset, isAISearchEnable)
  }

  const supportLoadMore = useMemo(() => {
    return searchFeedbackResult.hasMore
  }, [searchFeedbackResult])
  if (tableItem.length === 0) {
    return <ErrorView.Custom level="WARNING" message="No tickets found for this query" />
  }

  return (
    <TelemetryScope
      scope="SearchScreenSessionView"
      properties={{ searchSessionId: searchSessionId ? searchSessionId : '' }}
    >
      <ResizableColumnsTable
        className={styles.container}
        items={tableItem}
        columns={selectedColumns}
        keyPicker={keyPicker}
        supportLoadMore={supportLoadMore}
        loadMoreFunction={loadMoreFunction}
        virtualizationConfig={{
          itemSize: 56,
        }}
        enableColumnResizing={true}
        rowClick={actions.rowClick}
        selectedRowId={ticketId}
      />
    </TelemetryScope>
  )
})

SearchScreenTableView.displayName = 'SearchScreenTableView'
