import {
  ApiSearchTicketsTextPrefixType,
  ApiTicketsRequest,
  ApiTicketsWithTicketListRequest,
  ApiTicketType,
} from '@copilot-dash/api'
import { Times } from '@copilot-dash/core'
import { DefaultTriggeredSkillList, SearchTextPrefixType } from '@copilot-dash/domain'
import { ISearchTicketOptions } from './SearchTicketAction.types'
interface IParams {
  readonly searchId: string
  readonly utteranceOrResponseOdsSessionIdList: string[]
  readonly otherOdsSessionIdList: string[]
  readonly options: ISearchTicketOptions
  readonly odsSearchTriggered?: boolean
  readonly aiSearchTriggered?: boolean
}

export class BaseTicketSearchAction {
  protected createRequest(params: IParams): ApiTicketsWithTicketListRequest {
    const { utteranceOrResponseOdsSessionIdList, otherOdsSessionIdList, options } = params
    //TODO: Align new rule with backend to handle triggered skill
    const triggeredSkills = options.triggeredSkill
      ?.map((val) => {
        const triggeredSkill = DefaultTriggeredSkillList.find((skill) => skill.key === val)
        return triggeredSkill ? triggeredSkill.options : []
      })
      .flat()

    const hasErrorMessagesConditions = options.hasErrorMessages
      ?.map((condition) => {
        return condition.includes('&') ? condition.split('&') : condition
      })
      .flat()
    const { from, to } = Times.formatTimeRange(options.range ?? options.defaultRange, { timezone: 'UTC' })

    const requestBody: ApiTicketsRequest = {
      Count: options.count,
      Offset: options.offset,
      EmailAddress: params.aiSearchTriggered ? undefined : options.userId,
      ClientNames: options.client,
      ScenarioNames: options.channel,
      MetaData: params.aiSearchTriggered
        ? undefined
        : [
            ...(options.promptLanguages && options.promptLanguages.length ? [options.promptLanguages.join('|')] : []),
            ...(options.groundedPrompts?.length ? [options.groundedPrompts.join('|')] : []),
            ...(options.hasUserConsent?.length ? [options.hasUserConsent.join('|')] : []),
            ...(options.isApology?.length ? [options.isApology.join('|')] : []),
            ...(options.hasVerbatim?.length ? [options.hasVerbatim.join('|')] : []),
            ...(options.customerTypes?.length ? [options.customerTypes.join('|')] : []),
            ...(options.invocationType?.length ? [options.invocationType.join('|')] : []),
            ...(triggeredSkills ?? []),
            ...(hasErrorMessagesConditions ?? []),
            ...(options.hasCitation?.length ? [options.hasCitation.join('|')] : []),
            ...(options.hasEntityCard?.length ? [options.hasEntityCard.join('|')] : []),
            ...(options.hitAvalon?.length ? [options.hitAvalon.join('|')] : []),
            ...(options.isSTCAChina?.length ? [options.isSTCAChina.join('|')] : []),
            ...(options.isTopi18N?.length ? [options.isTopi18N.join('|')] : []),
            ...(options.responseHeroType?.length ? [options.responseHeroType.join('|')] : []),
            ...(options.responseLinkType?.length ? [options.responseLinkType.join('|')] : []),
            ...(options.semanticSearchType?.length ? [options.semanticSearchType.join('|')] : []),
            ...(options.bizchatScenario?.length ? [options.bizchatScenario.join('|')] : []),
            ...(options.experienceType?.length ? [options.experienceType.join('|')] : []),
            ...(options.hasConnector?.length ? [options.hasConnector.join('|')] : []),
            ...(options.hasGPTExtension?.length ? [options.hasGPTExtension.join('|')] : []),
            ...(options.hasMessageExtension?.length ? [options.hasMessageExtension.join('|')] : []),
            ...(options.hasCopilotExtensionIds?.length ? [options.hasCopilotExtensionIds.join('|')] : []),
            ...(options.errorCode?.length ? [options.errorCode.join('|')] : []),
            ...(options.isGCIntent?.length ? [options.isGCIntent.join('|')] : []),
            ...(options.hasConnectorResult?.length ? [options.hasConnectorResult.join('|')] : []),
            ...(options.agentTypes?.length ? [options.agentTypes.join('|')] : []),
            ...(options.appTypes?.length ? [options.appTypes.join('|')] : []),
          ],
      //ModelLanguages: options.modelLanguages,//Updated ModelLanguages to Utterance Language(from VSO Tag)
      Rings: options.ring,
      From: from,
      To: to,
      TicketType: ApiTicketType.SearchTicket,
      VerbatimFeedback: params.aiSearchTriggered
        ? undefined
        : options.searchTextPrefix === SearchTextPrefixType.Verbatim ||
            options.searchTextPrefix === SearchTextPrefixType.All
          ? options.searchText
          : undefined,
      TenantIds: params.aiSearchTriggered ? undefined : options.tenantIds,
      Thumbs: options.thumbs?.length === 1 ? options.thumbs[0] : undefined,
      ExternalTicketId: options.ticketId,
      Priorities: options.priority?.map(Number) ?? [],
      PrefixType: params.aiSearchTriggered ? undefined : this.generatePrefixType(options),
      DSATStatus: options.dSATStatus,
      CustomTags: params.aiSearchTriggered ? undefined : options.customTags,
      DSATAssignedTo: options.dSATAssignedTo,
      OdsSearchTriggered: params.aiSearchTriggered || params.odsSearchTriggered,
    }

    return {
      searchId: params.searchId,
      ODSSessionIdList: [],
      SearchTicketStatus: params.options.ticketStatus,
      TeamName: params.options.teamName,
      IssueId: params.options.issueId,
      VsoAccount: params.options.vsoAccount,
      RecommendedRootCauseId: params.options.recommendedRootCauseId,
      ClusteringIssueId: params.options.clusteringIssueId,
      ClusteringBatchId: params.options.batchId,
      TeamId: params.options.teamId,
      request: requestBody,
      UtteranceOrResponseOdsSessionIdList: utteranceOrResponseOdsSessionIdList,
      OtherOdsSessionIdList: otherOdsSessionIdList,
      IsOtherOdsSearchTriggered:
        params.aiSearchTriggered ||
        options.optionsSets ||
        options.copilotExtensionIds ||
        options.sliceIds ||
        options.flights
          ? true
          : false,
    }
  }

  protected generatePrefixType(options: ISearchTicketOptions): ApiSearchTicketsTextPrefixType {
    if (!options.searchText) return ApiSearchTicketsTextPrefixType.Unset
    if (options.searchTextPrefix === SearchTextPrefixType.Utterance)
      return ApiSearchTicketsTextPrefixType.OnlySearchUtterance
    if (options.searchTextPrefix === SearchTextPrefixType.Response)
      return ApiSearchTicketsTextPrefixType.OnlySearchResponse
    if (options.searchTextPrefix === SearchTextPrefixType.Verbatim)
      return ApiSearchTicketsTextPrefixType.OnlySearchVerbatim
    if (options.searchTextPrefix === SearchTextPrefixType.All)
      return ApiSearchTicketsTextPrefixType.SearchUtteranceOrResponseOrVerbatim
    return ApiSearchTicketsTextPrefixType.Unset
  }
}
