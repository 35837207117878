import { Doughnut } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartOptions } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { ProgressProps } from './Progress'
import { CardLoading } from '../../CardLoading'
import { Column, Row } from '../../../../../../../components/Layout'
import { useStyles } from '../components/Legend.style'
import { DoughnutHtmlLegendPlugin } from '../components/DoughnutHtmlLegendPlugin'
import { useMemo } from 'react'

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels)

const MAX_SHOW_LABEL_LENGTH = 10
const LabelColorMap = {
  Closed: '#0AB89B',
  'Root Caused': '#515EF5',
  'Team Assigned': '#F5A851',
  Untriaged: '#707070',
}
const STATE_PRIORITY = ['Untriaged', 'Team Assigned', 'Root Caused', 'Closed']
export const ProgressDoughnutChart: React.FC<ProgressProps> = ({ loading, stateList }) => {
  const styles = useStyles()
  const sortedStateList = [...stateList].sort(
    (a, b) => STATE_PRIORITY.indexOf(a.state) - STATE_PRIORITY.indexOf(b.state),
  )
  const labels = sortedStateList.map((item) => item.state)
  const dataValues = sortedStateList.map((item) => item.count)
  const total = useMemo(() => dataValues.reduce((sum, value) => sum + value, 0), [dataValues])

  const customColors = sortedStateList.map((item) => LabelColorMap[item.state])

  const plugins = [DoughnutHtmlLegendPlugin]
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'State Count',
        data: dataValues,
        backgroundColor: customColors,
        hoverBackgroundColor: customColors,
        borderWidth: 1,
      },
    ],
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const options: ChartOptions<any> = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 50,
        right: 20,
        top: 30,
        bottom: 30,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      htmlLegend: {
        containerID: 'progress-legend-container',
      },
      tooltip: {
        enabled: true,
      },
      datalabels: {
        display: 'auto',
        color: '#000000',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        formatter: (value: number, context: any) => {
          const label = context.chart.data.labels[context.dataIndex]
          const percentage = total > 0 ? ((value / total) * 100).toFixed(2) : '0.00'
          const truncatedLabel =
            label.length > MAX_SHOW_LABEL_LENGTH ? `${label.slice(0, MAX_SHOW_LABEL_LENGTH)}...` : label

          return `${truncatedLabel}\n${value} (${percentage}%)`
        },
        anchor: 'end',
        align: 'end',
        offset: 0,
        clamp: true,
      },
    },
    cutout: '70%',
  }

  if (loading) {
    return <CardLoading />
  }

  return (
    <Row fill style={{ width: '100%' }}>
      <Column style={{ height: '200px', width: '70%' }}>
        <Doughnut id="progress-doughnut-chart" data={data} options={options} plugins={plugins} />
      </Column>

      <Column id="progress-legend-container" className={styles.legendContainer} />
    </Row>
  )
}
