import { Controller, Control } from 'react-hook-form'
import { useMemo } from 'react'
import { Card } from './Card'
import { TimeFilter } from '../filters'
import { useStyles } from './Card.styles'
import { Body1Stronger } from '@fluentui/react-components'
import { ITicketFilterFormData } from '../TicketFilterForm/TicketFilterForm.types'
import { useTicketFilterStore } from '../../store/store'
import { Times } from '@copilot-dash/core'
import { useUserSetting } from '@copilot-dash/settings'
import { DateFilterCardFilters } from './config'
interface IProps {
  control: Control<ITicketFilterFormData>
  withTime?: boolean
}

export function DateCard({ control, withTime = false }: IProps) {
  const styles = useStyles()
  const filterPanelItems = useTicketFilterStore((state) => state.supportedTicketFilterItems)
  const [timezone] = useUserSetting('timeZone')

  // Define the date range for the date/time picker, adjusted for the user's preferred timezone.
  const [minDate, maxDate] = useMemo(() => {
    // `minDate` is set to March 18, 2024, as there is no feedback diagnostic data available before this date (in UTC).
    // `maxDate` is set to the current date.
    // Customers are restricted from selecting a date prior to March 18, 2024, or beyond the current date.
    return [Times.startOfDay(new Date('2024-03-18'), timezone), Times.endOfDay(new Date(), timezone)]
  }, [timezone])

  const hasDateCard = useMemo(() => {
    return filterPanelItems.some((item) => DateFilterCardFilters.includes(item))
  }, [filterPanelItems])

  if (!hasDateCard) return null

  return (
    <section className={styles.section}>
      <Body1Stronger>Date</Body1Stronger>
      <Card className={styles.card}>
        <Controller
          name="range"
          control={control}
          render={({ field }) => (
            <TimeFilter
              range={field.value}
              onChanged={field.onChange}
              withTime={withTime}
              minDate={minDate}
              maxDate={maxDate}
            />
          )}
        />
      </Card>
    </section>
  )
}
