import { ISearchScreenProps } from '../SearchScreen'
import { ISearchScreenState } from './ISearchScreenStoreState'
import { createSearchScreenStateForm } from './createSearchScreenStateForm'

export function createSearchScreenState(props: ISearchScreenProps): ISearchScreenState {
  return {
    searchSnapshot: { status: 'none' },
    productsSnapshot: { status: 'none' },
    loadMoreSnapshot: { 0: { status: 'none' } },
    searchFeedbackResult: { total: 0, tickets: [], hasMore: true },
    form: createSearchScreenStateForm(props.query),
    isColumnSettingPanelOpen: false,
    isTicketsFilterPanelOpen: true,
    isTicketSummaryPanelOpen: false,
    clickedTicketInfo: undefined,
    searchSessionId: undefined,
    focusedTab: props.query.tab || 'allFeedback',
    currentQuery: props.currentQuery,
  }
}
