import { makeStyles } from '@fluentui/react-components'

export const useStyles = makeStyles({
  ComboboxFilter: {
    maxHeight: '240px',
  },
  loading: {
    height: '100px',
  },
  container: {
    position: 'relative',
  },
  ComboboxFilterContainer: {
    paddingLeft: '14px',
  },
  peopleIcon: {
    position: 'absolute',
    left: '8px',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
    width: '16px',
    height: '16px',
  },
})
