import { z } from 'zod'

export interface ApiSearchItems {
  readonly id: string
  readonly verbatim?: string
  readonly utterance?: string
  readonly response?: string
  readonly userId?: string
  readonly createDateTime?: string
  readonly tenantId?: string
  readonly tags?: Array<string>
  readonly customTags?: Array<string>
}

export interface ApiAISearchResponse {
  readonly searchItems: Array<ApiSearchItems>
  readonly count: number
  readonly startIndex: number
  readonly stepNumber: number
}

export const apiSearchItemsSchema = z.object({
  id: z.string(),
  verbatim: z.string().optional(),
  utterance: z.string().optional(),
  response: z.string().optional(),
  userId: z.string().optional(),
  createDateTime: z.string().optional(),
  tenantId: z.string().optional(),
  tags: z.array(z.string()).optional(),
  customTags: z.array(z.string()).optional(),
})

export const apiAISearchResponseSchema = z.object({
  searchItems: z.array(apiSearchItemsSchema),
  count: z.number(),
  startIndex: z.number(),
  stepNumber: z.number(),
})
