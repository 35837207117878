import { useEffect, useMemo, useState } from 'react'
import {
  TagPicker,
  TagPickerList,
  TagPickerInput,
  TagPickerControl,
  TagPickerProps,
  TagPickerOption,
  TagPickerGroup,
  Tag,
  Field,
  makeStyles,
  tokens,
  useTagPickerFilter,
  Text,
} from '@fluentui/react-components'
import { UserPhoto } from '../../../../components/User/UserPhoto'
import { UserDisplayName } from '../../../../components/User/UserDisplayName'
import { useDebounce } from '../../../../hooks/useDebounce'
import { Row } from '../../../../components/Layout'

interface IProps {
  users: string[]
  onChangeUser: (value: string[]) => void
  emails: string[]
}

export const AssignedToSearch = ({ users, onChangeUser, emails }: IProps) => {
  const styles = useStyles()
  const [query, setQuery] = useState<string>('')

  const debouncedQuery = useDebounce(query, 300)

  useEffect(() => {
    if (debouncedQuery.trim().length > 0) {
      setQuery(debouncedQuery.trim())
    }
  }, [debouncedQuery])

  const onOptionSelect: TagPickerProps['onOptionSelect'] = (e, data) => {
    if (data.value === 'no-options' || data.value === 'no-matches') {
      return
    }
    onChangeUser?.(data.selectedOptions)
    setQuery('')
  }

  const options = useMemo(() => {
    if (debouncedQuery.trim().length > 0) {
      return emails.filter((email) => email.toLowerCase().includes(debouncedQuery.trim().toLowerCase()))
    } else {
      return emails
    }
  }, [debouncedQuery, emails])

  const children = useTagPickerFilter({
    query,
    options,
    noOptionsElement: <TagPickerOption value="no-matches">No results found</TagPickerOption>,
    renderOption: (option) => (
      <TagPickerOption
        key={option}
        media={<UserPhoto userId={option} className={styles.avatar} />}
        value={option}
        text={option}
      >
        <UserDisplayName userId={option} fallback={option} />
      </TagPickerOption>
    ),

    filter: (option) => !users.includes(option) && option.toLowerCase().includes(query.toLowerCase()),
  })

  return (
    <Field style={{ height: '32px' }}>
      <TagPicker onOptionSelect={onOptionSelect} selectedOptions={users}>
        <TagPickerControl className={styles.tagPicker}>
          <Row fill style={{ flexWrap: 'nowrap' }}>
            {users[0] && (
              <TagPickerGroup>
                <Tag
                  key={users[0]}
                  shape="rounded"
                  media={<UserPhoto userId={users[0]} className={styles.avatar} />}
                  value={users[0]}
                  style={{ width: '110px' }}
                >
                  <UserDisplayName userId={users[0]} className={styles.displayName} fallback={users[0]} />
                </Tag>
                {users.length > 1 && <Text>;+{users.length - 1}</Text>}
              </TagPickerGroup>
            )}
            <TagPickerInput
              style={{ minWidth: '0' }}
              placeholder={users?.length ? '' : 'Assigned to'}
              aria-label="Assigned to"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </Row>
        </TagPickerControl>
        <TagPickerList className={styles.tagPickerList}>
          {options.length === 0 ? (
            <TagPickerOption value="no-options">No results found</TagPickerOption>
          ) : (
            <>{children}</>
          )}
        </TagPickerList>
      </TagPicker>
    </Field>
  )
}

const useStyles = makeStyles({
  avatar: {
    width: '18px',
    height: '18px',
    borderRadius: '50%',
    fontSize: tokens.fontSizeBase200,
  },
  displayName: {
    width: '64px',
    fontSize: tokens.fontSizeBase300,
  },

  optionList: {
    maxHeight: '50vh',
  },
  tagPicker: {
    width: '180px',
    flexShrink: 0,
    flexGrow: 0,
    height: '32px',
    minWidth: '180px',
  },
  tagPickerList: {
    width: '280x',
    minWidth: '280px',
  },
})
